import React from 'react';
import {Link} from 'react-router-dom';

function Register() {
  const handleClick = e => {
    e.preventDefault();
    console.log('Berhasil menambahkan data');
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
      <div className="container-tight py-6">
        <form className="card card-md" action="." method="get">
          <div className="card-body">
            <div className="text-center mb-4">
              <img
                src={`${process.env.PUBLIC_URL}./static/logo.svg`}
                height="36"
                alt=""
              />
            </div>
            <div className="mb-3">
              <label htmlFor="true" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter name"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="true" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter email"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="true" className="form-label">
                Password
              </label>
              <div className="input-group input-group-flat">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  autoComplete="off"
                />
                <span className="input-group-text">
                  <a
                    aria-label="Show Password"
                    href="#show_password"
                    className="link-secondary"
                    title="Show password"
                    data-bs-toggle="tooltip">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="12" cy="12" r="2" />
                      <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                    </svg>
                  </a>
                </span>
              </div>
            </div>
            <div className="mb-3">
              <label className="form-check">
                <input type="checkbox" className="form-check-input" />
                <span className="form-check-label">
                  Agree the{' '}
                  <a href="#terms_policy" tabIndex="-1">
                    terms and policy
                  </a>
                  .
                </span>
              </label>
            </div>
            <div className="form-footer">
              <button
                type="button"
                onClick={e => handleClick(e)}
                className="btn btn-primary w-100">
                Create new account
              </button>
            </div>
          </div>
        </form>
        <div className="text-center text-muted mt-3">
          Sudah punya akun? <Link to="/">Login</Link>
        </div>
      </div>
    </div>
  );
}

export default Register;
