import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

function CardDescMini({
  col,
  bgcolor,
  iconName,
  iconColor,
  title,
  subtitle,
  onClick,
}) {
  return (
    <div
      aria-label="Card Button"
      className={col}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}>
      <div className="card card-sm">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-auto">
              <span className={`${bgcolor} text-white avatar`}>
                <Icon name={iconName} color={iconColor} size={20} />
              </span>
            </div>
            <div className="col">
              <div className="" style={{fontSize: 14}}>
                {title}
              </div>
              <div className="text-muted">{subtitle}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CardDescMini.propTypes = {
  col: PropTypes.string.isRequired,
  bgcolor: PropTypes.string,
  iconName: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

CardDescMini.defaultProps = {
  bgcolor: 'bg-azure',
  iconColor: '#fff',
  onClick: () => {},
};

export default CardDescMini;
