import React, {useCallback, useEffect, useState} from 'react';

import axios from 'axios';
import {HabibiTable, SelectColumnFilter} from '../../components/HabibiTable';
import Card from '../../components/SimpleCard';
import Icon from '../../components/Icon';
import LoadingView from '../../components/Loading';

function Issues() {
  // State & LoadData
  const [loading, setLoading] = useState(false);
  const [issues, setIssues] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: '',
    content: '',
    link: '',
  });

  const fetchIssues = useCallback(async () => {
    // Get API
    await axios
      .all([
        axios.get(
          'https://api.trello.com/1/boards/5e8d9133a8404042c092a1ee/cards?key=24a11a4f49230664878f864ffcc71696&token=8f3c5769785dee6b4c14d8d8866cbc7c1714c47cb7fd1e0673433a7ae2479a53&fields=idMembers,idList,dateLastActivity,name,desc,labels,shortUrl,isTemplate,due,badges'
        ),
        axios.get(
          'https://api.trello.com/1/boards/5e8d9133a8404042c092a1ee/lists?fields=id,name&key=24a11a4f49230664878f864ffcc71696&token=8f3c5769785dee6b4c14d8d8866cbc7c1714c47cb7fd1e0673433a7ae2479a53'
        ),
        axios.get(
          'https://api.trello.com/1/boards/5e8d9133a8404042c092a1ee/members?key=24a11a4f49230664878f864ffcc71696&token=8f3c5769785dee6b4c14d8d8866cbc7c1714c47cb7fd1e0673433a7ae2479a53'
        ),
      ])
      .then(
        axios.spread((resIssues, resLists, resEmployee) => {
          const issue = resIssues.data;
          const employee = resEmployee.data;
          const list = resLists.data;
          const issuesTmp = [];

          issue.forEach(item => {
            const assignTo = [];
            const priority = [];
            const division = [];
            const status = [];

            // Cleansing type template
            if (item.isTemplate !== true) {
              // Get assign to
              if (item.idMembers.length !== 0) {
                item.idMembers.forEach(members => {
                  assignTo.push(
                    employee.find(employees => employees.id === members)
                  );
                });
              }

              // Status
              status.push(list.find(lists => lists.id === item.idList));

              // Searching priority and division
              item.labels.forEach(labels => {
                const labelSplit = labels.name.split(' ');
                if (labelSplit[0] === 'Division') {
                  division.push(labelSplit[2]);
                }
                if (labelSplit[0] === 'Priority') {
                  priority.push(labelSplit[2]);
                }
              });

              // Save data to tmp issues
              issuesTmp.push({
                subject: item.name,
                desc: item.desc,
                status: status[0].name,
                assignTo,
                createdAt: item.dateLastActivity,
                division,
                priority: priority[0],
                progress:
                  (item.badges.checkItemsChecked / item.badges.checkItems) *
                  100,
                link: item.shortUrl,
                due: item.due,
              });
            }
          });

          setIssues(issuesTmp);
        })
      )
      .catch(error => console.log('Catch:', error))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchIssues();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchIssues();
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  });

  // Controller

  // View
  const headerView = () => (
    <>
      <div className="page-header">
        <div className="row align-items-center">
          {/* Title */}
          <div className="col">
            <div className="page-pretitle">Menu</div>
            <h2 className="page-title">Issues</h2>
          </div>

          {/* Actions Button */}
          <div className="col-auto ml-auto d-print-none">
            <a
              className="btn btn-gray"
              style={{marginLeft: 10}}
              href="https://trello.com/b/t0CyfMma/issues-management-ticketing"
              target="_blank"
              rel="noreferrer">
              <div className="d-flex align-items-center">
                <Icon name="PlusCircle" size={16} style={{marginRight: 10}} />{' '}
                <span>Tambah Issues</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );

  const issuesView = () => {
    const columns = [
      {
        Header: 'No',
        id: 'row',
        width: '5%',
        Cell: cellInfo => <div>{cellInfo.row.index + 1}</div>,
      },
      {
        Header: 'Date',
        id: 'date',
        accessor: 'createdAt',
        Filter: SelectColumnFilter,
        filter: 'includes',
        sortType: 'basic',
        width: '10%',
        Cell: cellInfo => {
          if (typeof cellInfo.row.original.createdAt !== 'undefined') {
            const tanggal = cellInfo.row.original.createdAt.split('T');
            const detailTgl = tanggal[0].split('-');
            return `${detailTgl[2]}-${detailTgl[1]}-${detailTgl[0]}`;
          }
          return '-';
        },
      },
      {
        Header: 'Subject',
        accessor: 'subject',
        width: '40%',
        Cell: cellInfo => (
          <div>
            {cellInfo.row.original.subject}
            <div style={{fontSize: '14px'}}>
              Sumber:{' '}
              <a
                href={cellInfo.row.original.link}
                target="_blank"
                rel="noreferrer"
                style={{color: '#777'}}>
                Trello Card
              </a>
            </div>
          </div>
        ),
      },
      {
        Header: 'Division',
        accessor: 'division',
        width: '10%',
        Filter: SelectColumnFilter,
        filter: 'includes',
        Cell: cellInfo => {
          let color;
          const {division} = cellInfo.row.original;

          if (division.length <= 0) {
            return <b>-</b>;
          }
          return division.map((item, index) => {
            if (item === 'Agronomis') color = 'bg-lime';
            else if (item === 'Electrical') color = 'bg-red';
            else if (item === 'Mechanical') color = 'bg-orange';
            else if (item === 'Produksi') color = 'bg-purple';
            else if (item === 'Software') color = 'bg-indigo';
            else color = 'bg-dark';

            return (
              <div key={item}>
                <span
                  className={`badge ${color} mt-1 mb-1 text-capitalize`}
                  style={{width: '100%'}}>
                  <b>{item}</b>
                </span>
              </div>
            );
          });
        },
      },
      {
        Header: 'Assign To',
        accessor: 'assignTo',
        width: '15%',
        Cell: cellInfo => {
          const {assignTo} = cellInfo.row.original;
          if (assignTo.length <= 0) {
            return <b>-</b>;
          }
          return (
            <ul className="p-0 my-auto">
              {assignTo.map((item, index) => (
                <li key={item.id} className="text-capitalize">
                  {item.fullName === 'Luqman' ? (
                    <span className="d-flex align-items-center">
                      Maman Racing{' '}
                      <a
                        aria-label="Alert"
                        href="https://www.youtube.com/watch?v=YgLftFEcKiM"
                        target="_blank"
                        rel="noreferrer">
                        <Icon
                          name="AlertCircle"
                          size={13}
                          style={{marginLeft: 5}}
                        />
                      </a>
                    </span>
                  ) : (
                    item.fullName
                  )}
                </li>
              ))}
            </ul>
          );
        },
      },
      {
        Header: 'Priority',
        accessor: 'priority',
        width: '10%',
        Filter: SelectColumnFilter,
        filter: 'includes',
        Cell: cellInfo => {
          let color;
          if (cellInfo.row.original.priority === 'High') {
            color = 'bg-red';
          } else if (cellInfo.row.original.priority === 'Medium') {
            color = 'bg-orange';
          } else {
            color = 'bg-yellow';
          }
          return cellInfo.row.original.priority === null ? (
            '-'
          ) : (
            <span className={`badge ${color}`} style={{width: '100%'}}>
              <b>{cellInfo.row.original.priority}</b>
            </span>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '10%',
        Filter: SelectColumnFilter,
        filter: 'includes',
        Cell: cellInfo => {
          let color = '';
          if (cellInfo.row.original.status === 'Waiting') {
            color = 'bg-secondary';
          } else if (cellInfo.row.original.status === 'Open') {
            color = 'bg-blue';
          } else if (cellInfo.row.original.status === 'On Progress') {
            color = 'bg-yellow';
          } else if (cellInfo.row.original.status === 'Review') {
            color = 'bg-orange';
          } else {
            color = 'bg-green';
          }
          return cellInfo.row.original.status === null ? (
            '-'
          ) : (
            <div>
              <span className={`badge ${color}`} style={{width: '100%'}}>
                <b>{cellInfo.row.original.status}</b>
              </span>
              {Number.isNaN(cellInfo.row.original.progress) ? null : (
                <div className="progress mt-1" style={{height: 10}}>
                  <div
                    className="progress-bar bg-green"
                    role="progressbar"
                    style={{width: `${cellInfo.row.original.progress}%`}}
                    aria-valuenow={cellInfo.row.original.progress}
                    aria-valuemin="0"
                    aria-valuemax="100">
                    <b>{Math.round(cellInfo.row.original.progress)}%</b>
                  </div>
                </div>
              )}
            </div>
          );
        },
      },
    ];

    return (
      <div className="row row-deck row-cards">
        <div className="col-12">
          <Card styleBody={{padding: 0, paddingTop: 20}}>
            <HabibiTable
              title="Issues"
              columns={columns}
              data={issues}
              formOne={3}
              formTwo={5}
              formThree={6}
              rowSize={[50, 100, 150]}
            />
          </Card>
        </div>
      </div>
    );
  };

  return (
    <div className="container-xl">
      {/* header */}
      {headerView()}

      {/* content */}
      {loading ? <LoadingView /> : issuesView()}

      {/* Modal */}
      <div
        className={`modal modal-blur fade ${
          modalVisible ? ' show d-block' : ' d-none'
        }`}
        tabIndex="-1"
        role="dialog"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" style={{border: '1px solid #eee'}}>
            <div className="modal-header">
              <h5 className="modal-title">{modalContent.title}</h5>
              {/* <button onClick={() => setModalVisible(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"/><line x1="18" y1="6" x2="6" y2="18" /><line x1="6" y1="6" x2="18" y2="18" /></svg>
              </button> */}
            </div>
            <div className="modal-body">
              <div dangerouslySetInnerHTML={{__html: modalContent.content}} />
              <p className="text-center" style={{marginTop: '20px'}}>
                Untuk melihat detail klik tautan berikut{' '}
                <a href={modalContent.link} target="_blank" rel="noreferrer">
                  Link Trello
                </a>
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-white ml-auto"
                data-dismiss="modal"
                onClick={() => setModalVisible(false)}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Issues;
