/* eslint-disable prefer-const */
import React, {useState, useEffect, useCallback} from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import {useParams} from 'react-router-dom';
import Select from 'react-select';
import Card from '../../../components/SimpleCard';
import {newAPI, oldAPI} from '../../../services/baseAPI';

function Growth() {
  const {farmId} = useParams();
  const [statusDevice, setStatusDevice] = useState(false);
  const [optionPlant, setOptionPlant] = useState([]);
  const [choosePlant, setChoosePlant] = useState([]);
  const [data, setData] = useState([]);
  const [graph, setGraph] = useState({
    options: {
      chart: {
        id: 'graph',
      },
      xaxis: {
        type: 'date',
        min: undefined,
        max: undefined,
        categories: [],
      },
      yaxis: {
        min: undefined,
        max: undefined,
      },
      noData: {
        text: 'Pilih Data',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '14px',
          fontFamily: undefined,
        },
      },
      type: 'line',
    },
  });
  const [dataDate, setDataDate] = useState([]);
  const [dataParameter, setDataParameter] = useState([]);
  const [heatmap, setHeatmap] = useState({
    chart: {
      height: 350,
      type: 'heatmap',
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        radius: 30,
        useFillColorAsStroke: true,
        colorScale: {
          ranges: [
            {
              from: 1,
              to: 2,
              name: 'Hidup',
              color: '#00A100',
            },
            {
              from: -1,
              to: 0,
              name: 'Mati',
              color: '#FF0000',
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
    },
  });
  const [jenisHeatmap, setJenisHeatmap] = useState([0, 0]);
  const [dataHeatmap, setDataHeatmap] = useState([]);
  const [bedengan, setBedengan] = useState([]);

  const fetchFarm = useCallback(async () => {
    await newAPI
      .get(`/growth/${farmId}/showfarm`)
      .then(res => {
        let tmpOptions = [];
        res.data.forEach(item => {
          tmpOptions.push({
            value: item.id,
            label: item.plant_name,
          });
        });
        setOptionPlant(tmpOptions);
      })
      .catch(err => {
        console.log(`error : ${err}`);
      });
  }, []);

  const fetchGraphicGrowth = useCallback(async id => {
    await newAPI
      .get(`/growth/${id}/show`)
      .then(res => {
        const array = [];
        const graphTemp = graph;
        const arrayDate = [];
        const arrayGraphic = [];
        const growth = res.data;
        const parameterArray = growth[1];

        setData(growth[0][0]);
        const arrayColumn = (arr, n) => arr.map(x => x[n]);
        parameterArray.forEach(element => {
          const arrayTemp = JSON.parse(element.parameters_data);
          const arrayTmp = [];
          for (let i = 0; i < arrayTemp[0].length; i += 1) {
            const arraySum = arrayColumn(arrayTemp, i).reduce(
              (a, b) => parseFloat(a) + parseFloat(b),
              0
            );
            arrayTmp.push(
              (
                parseFloat(arraySum / arrayTemp.length) *
                JSON.parse(growth[0][0].converts)[i]
              ).toFixed(2)
            );
          }
          arrayDate.push(moment(element.created_at).format('DD/MM/Y'));
          array.push(arrayTmp);
        });
        JSON.parse(growth[0][0].parameters).forEach((e, i) => {
          const arrayTmp = {};
          const arrayDataTemp = [];
          array.forEach(arr => {
            arrayDataTemp.push(arr[i]);
          });
          console.log(arrayDataTemp);
          arrayTmp.data = arrayDataTemp;
          arrayTmp.name = e;
          arrayTmp.key = i;
          arrayGraphic.push(arrayTmp);
        });
        graphTemp.options.xaxis.categories = arrayDate;
        setGraph(graphTemp);
        setDataParameter(arrayGraphic);
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => {});
  }, []);

  const fetchHeatmapShow = useCallback(async id => {
    await newAPI
      .get(`/growth/${id}/heatmap`)
      .then(res => {
        const array2 = [];
        let array3 = [];

        const heatmapData = JSON.parse(res.data[res.data.length - 1].data);
        heatmapData.forEach((element, i) => {
          array3 = [];
          element.forEach((e, j) => {
            array3.push({
              x: `Baris-${j + 1}`,
              y: e[0],
            });
          });

          array2.push({
            name: `Bedengan-${i + 1}`,
            data: array3,
          });
        });
        setBedengan(res.data);
        setJenisHeatmap([res.data.length - 1, 0]);
        setDataHeatmap([...array2]);
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => {});
  }, []);

  useEffect(() => {
    fetchFarm();
  }, []);

  const changePlant = e => {
    setChoosePlant(e);
    setData([]);
    setDataDate([]);
    setDataParameter([]);

    setJenisHeatmap([0, 0]);
    setDataHeatmap([]);
    setBedengan([]);

    fetchGraphicGrowth(e.value);
    fetchHeatmapShow(e.value);
  };

  return (
    <>
      {/* pilih tanaman */}
      <div className="col-md-12">
        <Card>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Pilih Tanaman
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={choosePlant}
                  onChange={changePlant}
                  options={optionPlant}
                  placeholder="Pilih tanaman"
                />
              </label>
            </div>
          </div>
        </Card>
      </div>

      {/* Grafik */}
      <div className="col-md-12">
        <Card className=" mx-auto" title="Grafik Pertumbuhan">
          <div className="row">
            <div className="col-9 text-center">
              <Card title="Data Tanaman" className="d-flex  py-1">
                <div className="row">
                  <div className="col-4">
                    <label htmlFor="true" className="form-label">
                      Jenis Grafik
                      <select
                        className="form-control"
                        onChange={e => {
                          console.log(e.target.value);
                          setGraph({
                            options: {
                              ...graph.options,
                              type: e.target.value,
                            },
                          });
                        }}>
                        <option value="line">Line</option>
                        <option value="area">Area</option>
                        <option value="bar">Bar</option>
                      </select>
                    </label>
                  </div>
                  <div className="col-4">
                    <label htmlFor="true" className="form-label">
                      Min
                      <input
                        name="min"
                        type="number"
                        className="form-control"
                        onChange={e => {
                          if (e.target.value) {
                            setGraph({
                              options: {
                                ...graph.options,
                                yaxis: {
                                  ...graph.options.yaxis,
                                  min: parseInt(e.target.value, 10),
                                },
                              },
                            });
                          } else {
                            setGraph({
                              options: {
                                ...graph.options,
                                yaxis: {
                                  ...graph.options.yaxis,
                                  min: undefined,
                                },
                              },
                            });
                          }
                        }}
                      />
                    </label>
                  </div>
                  <div className="col-4">
                    <label htmlFor="true" className="form-label">
                      Max
                      <input
                        name="max"
                        type="number"
                        className="form-control"
                        onChange={e => {
                          if (e.target.value) {
                            setGraph({
                              options: {
                                ...graph.options,
                                yaxis: {
                                  ...graph.options.yaxis,
                                  max: parseInt(e.target.value, 10),
                                },
                              },
                            });
                          } else {
                            setGraph({
                              options: {
                                ...graph.options,
                                yaxis: {
                                  ...graph.options.yaxis,
                                  max: undefined,
                                },
                              },
                            });
                          }
                        }}
                      />
                    </label>
                  </div>
                </div>
                <ReactApexChart
                  type={graph.options.type}
                  height={450}
                  options={graph.options}
                  series={dataDate}
                />
              </Card>
            </div>
            <div className="col-3">
              <Card title="Parameter" className="d-flex  py-1">
                {data.parameters &&
                  JSON.parse(data.parameters).map((x, i) => (
                    <div key={x} className="row" style={{marginBottom: '4px'}}>
                      <div className="col-md-12">
                        <label>
                          <input
                            type="checkbox"
                            onChange={e => {
                              const index = dataDate.findIndex(
                                p => p.key === i
                              );
                              if (e.target.checked) {
                                setDataDate([...dataDate, dataParameter[i]]);
                              } else if (dataDate.length === 1) {
                                setDataDate([]);
                              } else {
                                dataDate.splice(index, 1);
                                const dataD = dataDate.pop();
                                setDataDate([...dataDate, dataD]);
                              }
                              console.log(dataDate);
                            }}
                          />
                          <span style={{marginLeft: '4px'}}>{x}</span>
                        </label>
                      </div>
                    </div>
                  ))}
              </Card>
            </div>
          </div>
        </Card>
      </div>
      {/*  Heat Map */}
      <div className="col-md-12">
        <Card title="Heatmap">
          <div className="row text-center">
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Tanggal
                <select
                  className="form-control"
                  name="date"
                  onChange={k => {
                    const heatmapData = JSON.parse(
                      bedengan[k.target.value].data
                    );
                    const array2 = [];
                    let array3 = [];
                    heatmapData.forEach((element, i) => {
                      array3 = [];
                      element.forEach((e, j) => {
                        array3.push({
                          x: `Baris-${j + 1}`,
                          y: e[jenisHeatmap[1]],
                        });
                      });

                      array2.push({
                        name: `Bedengan-${i + 1}`,
                        data: array3,
                      });
                    });
                    setJenisHeatmap([k.target.value, jenisHeatmap[1]]);
                    setDataHeatmap([...array2]);
                  }}>
                  {bedengan.map((e, i) => (
                    <option selected value={i}>
                      {moment(e.date).format('DD-MM-YYYY')}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Jenis Data
                <select
                  className="form-control"
                  name="type"
                  onChange={k => {
                    const heatmapData = JSON.parse(
                      bedengan[jenisHeatmap[0]].data
                    );
                    const array2 = [];
                    let array3 = [];
                    heatmapData.forEach((element, i) => {
                      array3 = [];
                      element.forEach((e, j) => {
                        array3.push({
                          x: `Baris-${j + 1}`,
                          y: e[k.target.value],
                        });
                      });

                      array2.push({
                        name: `Bedengan-${i + 1}`,
                        data: array3,
                      });
                    });
                    if (k.target.value === '0') {
                      setHeatmap({
                        chart: {
                          height: 350,
                          type: 'heatmap',
                        },
                        plotOptions: {
                          heatmap: {
                            shadeIntensity: 0.5,
                            radius: 30,
                            useFillColorAsStroke: true,
                            colorScale: {
                              ranges: [
                                {
                                  from: 1,
                                  to: 2,
                                  name: 'Hidup',
                                  color: '#00A100',
                                },
                                {
                                  from: -1,
                                  to: 0,
                                  name: 'Mati',
                                  color: '#FF0000',
                                },
                              ],
                            },
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        stroke: {
                          width: 1,
                        },
                      });
                    } else {
                      setHeatmap({
                        chart: {
                          height: 350,
                          type: 'heatmap',
                        },
                        plotOptions: {
                          heatmap: {
                            shadeIntensity: 0.5,
                            radius: 30,
                            useFillColorAsStroke: true,
                            colorScale: {
                              ranges: [
                                {
                                  from: -1,
                                  to: 0,
                                  name: 'No HPT',
                                  color: '#00A100',
                                },
                                {
                                  from: 1,
                                  to: 2,
                                  name: 'HPT',
                                  color: '#FF0000',
                                },
                              ],
                            },
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        stroke: {
                          width: 1,
                        },
                      });
                    }
                    setJenisHeatmap([jenisHeatmap[0], k.target.value]);
                    setDataHeatmap([...array2]);
                  }}>
                  <option value="0">Status</option>
                  <option value="1">HPT</option>
                </select>
              </label>
            </div>
          </div>

          <ReactApexChart
            type="heatmap"
            height={450}
            options={heatmap}
            series={dataHeatmap}
          />
        </Card>
      </div>
    </>
  );
}

export default Growth;
