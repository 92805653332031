import React, {useState, useEffect, useCallback} from 'react';
import {Link} from 'react-router-dom';
import {newAPI} from '../../services/baseAPI';
import Icon from '../../components/Icon';
import useUserStore from '../../stores/Users';
import Card from '../../components/SimpleCard';
import LoadingView from '../../components/Loading';
import {HabibiTable, SelectColumnFilter} from '../../components/HabibiTable';

function Farmer() {
  // State
  const ADMIN = 1;
  const OWNER = 2;
  const FARMER = 3;
  const GUEST = 5;
  const AGRONOMIS = 6;
  const ENGINEER = 7;
  const token = useUserStore(state => state.token);
  const users = useUserStore(state => state.user);
  const [farmer, setFarmer] = useState([]);
  const [loading, setLoading] = useState(false);

  // Request Data
  const fetchFarmer = useCallback(async () => {
    setLoading(true);
    console.log('start');

    await newAPI
      .get('/v3/users', {headers: {Authorization: `Bearer ${token}`}})
      .then(res => {
        setFarmer(res.data.data);
        console.log(res);
      })
      .catch(error => {
        console.log(`error ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setFarmer, setLoading, token]);

  useEffect(() => {
    fetchFarmer();
  }, [fetchFarmer]);

  // Config
  const columns = [
    {
      Header: 'No',
      accessor: 'no',
      width: '10%',
      Cell: cellInfo => <span>{cellInfo.row.index + 1}</span>,
    },
    {
      Header: `Nama ${users.status === ADMIN ? 'Pengguna' : 'Petani'}`,
      accessor: 'first_name',
      filter: 'includes',
      width: '40%',
      Cell: cellInfo => (
        <span>
          {cellInfo.row.original.first_name} {cellInfo.row.original.last_name}
        </span>
      ),
    },
    {Header: 'Email', accessor: 'email', width: '20%'},
    {Header: 'Telepon', accessor: 'phone', width: '20%'},
    {
      Header: 'Status',
      accessor: 'display_name',
      Filter: SelectColumnFilter,
      filter: 'includes',
      width: '10%',
      Cell: cellInfo => {
        let color;
        let role;
        switch (cellInfo.row.original.role_id) {
          case ADMIN:
            color = 'badge bg-green w-100';
            break;

          case OWNER:
            color = 'badge bg-blue w-100';
            break;

          case FARMER:
            color = 'badge bg-orange w-100';
            break;

          case AGRONOMIS:
            color = 'badge bg-green w-100';
            break;

          case ENGINEER:
            color = 'badge bg-green w-100';
            break;

          default:
            color = 'badge bg-yellow w-100';
            break;
        }
        return (
          <span className={color}>{cellInfo.row.original.display_name}</span>
        );
      },
    },
    {
      Header: '',
      accessor: 'action',
      Filter: false,
      disableSorting: true,
      Cell: cellInfo => (
        <div className="text-end">
          <span className="dropdown">
            <button
              type="button"
              className="btn dropdown-toggle align-text-top"
              data-bs-boundary="viewport"
              data-bs-toggle="dropdown">
              Actions
            </button>
            <div className="dropdown-menu dropdown-menu-end">
              <Link
                className="dropdown-item"
                to={`/farmer/${cellInfo.row.original.id}/show`}>
                Show
              </Link>
              <Link
                className="dropdown-item"
                to={`/farmer/${cellInfo.row.original.id}/edit`}>
                Edit
              </Link>
            </div>
          </span>
        </div>
      ),
    },
  ];

  // Controller

  // View
  const headerView = () => (
    <>
      <div className="page-header d-print-none">
        <div className="row align-items-center">
          <div className="col">
            <div className="page-pretitle">Menu</div>
            <h2 className="page-title">
              {users.status === ADMIN ? 'Pengguna' : 'Petani'}
            </h2>
          </div>

          {/* Actions Button */}
          {users.status === ADMIN ||
          users.status === AGRONOMIS ||
          users.status === ENGINEER ? (
            <div className="col-auto ml-auto d-print-none">
              <Link to="farmer/create">
                <button type="button" className="btn ml-2 btn-gray">
                  <div className="d-flex align-items-center">
                    <Icon
                      name="PlusCircle"
                      size={16}
                      style={{marginRight: 5}}
                    />{' '}
                    <span>
                      Tambah {users.status === ADMIN ? 'Pengguna' : 'Petani'}
                    </span>
                  </div>
                </button>
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );

  const adminView = () => (
    <div className="row row-deck row-cards">
      <div className="col-12">
        <Card styleBody={{padding: 0, paddingTop: 20}}>
          <HabibiTable
            title={users.status === ADMIN ? 'pengguna' : 'petani'}
            columns={columns}
            data={farmer}
            formOne={users.status === ADMIN ? 4 : null}
            formTwo={null}
            formThree={null}
            rowSize={[10, 30, 50]}
          />
        </Card>
      </div>
    </div>
  );

  const ownerView = () => (
    <div className="row row-deck row-cards">
      <div className="col-12">
        <Card styleBody={{padding: 0, paddingTop: 20}}>
          <HabibiTable
            title="petani"
            columns={columns}
            data={farmer}
            formOne={null}
            formTwo={null}
            formThree={null}
            rowSize={[10, 30, 50]}
          />
        </Card>
      </div>
    </div>
  );

  const handleView = () => {
    switch (users.status) {
      case ADMIN:
      case AGRONOMIS:
      case ENGINEER:
        return adminView();

      case OWNER:
        return ownerView();

      default:
        return null;
    }
  };

  return (
    <div className="container-xl">
      {/* header */}
      {headerView()}

      {/* content */}
      {loading ? <LoadingView /> : handleView()}
    </div>
  );
}

export default Farmer;
