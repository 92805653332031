import React, {useState, useEffect, useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {Link, useHistory} from 'react-router-dom';
import Swal from 'sweetalert2';
import {awsKelanaAPI, newAPI} from '../../services/baseAPI';
import Icon from '../../components/Icon';
import useUserStore from '../../stores/Users';
import Card from '../../components/SimpleCard';
import LoadingView from '../../components/Loading';

function KelanaCreateFarm() {
  // State
  const ADMIN = 1;
  const OWNER = 2;
  const token = useUserStore(state => state.token);
  const users = useUserStore(state => state.user);
  const {register, errors, handleSubmit, setValue} = useForm();
  const history = useHistory();

  const [data, setData] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const storeFarm = async params => {
    setIsLoading(true);
    const body = {
      farm_name: params.farm_name,
      loc: params.loc,
      city: params.city,
      status_farm: params.status_farm,
      area_farm: params.area_farm,
      type_soil: params.type_soil,
    };
    awsKelanaAPI
      .post('/', body, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'text/plain',
        },
      })
      .then(res => {
        if (res.status === 200) {
          Swal.fire('Saved!', 'pengguna berhasil ditambahkan!', 'success');
          console.log(`berhasil upload ${JSON.stringify(res)}`);
        }
      })
      .catch(error => {
        console.log(`error ${error}`);
        Swal.fire('Changes are not saved', 'there is something wrong', 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const header = () => (
    <>
      <div className="page-header d-print-none">
        <div className="row align-items-center">
          <button
            aria-label="Back Button"
            type="button"
            onClick={() => history.push('/kelana')}
            className="col-2"
            style={{
              marginLeft: '8px',
              marginRight: '10px',
              height: 40,
              width: 40,
              borderRadius: 20,
              backgroundColor: 'rgba(255, 0, 0, 0)',
              borderWidth: 0,
            }}>
            <Icon name="ArrowLeft" color="#333" size={20} />
          </button>
          <div className="col">
            <div className="page-pretitle">Tambah</div>
            <h2 className="page-title">Kebun Kelana</h2>
          </div>
        </div>
      </div>
    </>
  );
  const createView = () => (
    <>
      <Card title="Form Tambah Kebun Kelana">
        <form onSubmit={handleSubmit(storeFarm)}>
          <div className="row row-decks row-cards">
            {/* nama kebun */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Nama Kebun
              </label>
              <input
                ref={register({required: true})}
                name="farm_name"
                type="text"
                className="form-control"
                placeholder="Masukan nama kebun"
              />
              {errors.farm_name && (
                <div className="pt-1" style={{color: 'red'}}>
                  Nama Kebun wajib diisi
                </div>
              )}
            </div>

            {/* Area */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Luas Lahan (ha)
              </label>
              <input
                ref={register({required: true})}
                name="area_farm"
                type="number"
                className="form-control"
                placeholder="Masukan Luas lahan"
                step={0.01}
                min={0}
              />
              {errors.area_farm && (
                <div className="pt-1" style={{color: 'red'}}>
                  Area lahan wajib diisi
                </div>
              )}
            </div>

            {/* Lokasi */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Lokasi
              </label>
              <input
                ref={register({required: true})}
                name="loc"
                type="text"
                className="form-control"
                placeholder="Masukan Lokasi"
              />
              {errors.loc && (
                <div className="pt-1" style={{color: 'red'}}>
                  Lokasi wajib diisi
                </div>
              )}
            </div>

            {/* kota */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Kota
              </label>
              <input
                ref={register({required: true})}
                name="city"
                type="text"
                className="form-control"
                placeholder="Masukan Lokasi"
              />
              {errors.city && (
                <div className="pt-1" style={{color: 'red'}}>
                  Kota wajib diisi
                </div>
              )}
            </div>

            {/* jenis tanah */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Jenis Tanah
              </label>
              <select
                name="type_soil"
                ref={register({required: true})}
                className="form-select form-control">
                <option value="Umum">Umum</option>
                <option value="Pasir">Pasir</option>
                <option value="Lempung">Lempung</option>
                <option value="Liat">Liat</option>
              </select>
              {errors.type_soil && (
                <div className="pt-1" style={{color: 'red'}}>
                  Jenis tanah wajib diisi
                </div>
              )}
            </div>
            {/* Status */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Status
              </label>
              <select
                name="status_farm"
                ref={register({required: true})}
                className="form-select form-control">
                <option value="aktif">Aktif</option>
                <option value="hapus">Non-Aktif</option>
              </select>
              {errors.type_soil && (
                <div className="pt-1" style={{color: 'red'}}>
                  Jenis tanah wajib diisi
                </div>
              )}
            </div>

            {/* tombol */}
            <div className="col-md-12">
              <input
                className="btn w-100 btn-primary px-5"
                type="submit"
                value="Tambah Kebun"
              />
            </div>
          </div>
        </form>
      </Card>
    </>
  );

  return (
    <div className="container-xl">
      {header()}

      <div className="row">
        {/* content */}
        {isLoading ? (
          <div className="col-md-12">
            <LoadingView />
          </div>
        ) : (
          createView()
        )}
      </div>
    </div>
  );
}

export default KelanaCreateFarm;
