/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect, useCallback} from 'react';
import {Link, useParams, Route} from 'react-router-dom';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

import useUserStore from '../../stores/Users';
import Icon from '../../components/Icon';
import Card from '../../components/SimpleCard';
import LoadingView from '../../components/Loading';
import Dropdown from '../../components/Dropdown';
import {HabibiTable, SelectColumnFilter} from '../../components/HabibiTable';
import './Agriculture.css';
import Npk from './npk/index';

import {
  fetchArticle,
  fetchDisease,
  fetchGrowth,
  fetchPlant,
  fetchCultivation,
  fetchFertilizer,
} from '../../controllers/agricultureController';
import {newURI} from '../../services/baseAPI';

const menus = {
  article: 'Artikel',
  disease: 'Hama Penyakit',
  growth: 'Data Pertumbuhan',
  plant: 'Tanaman',
  cultivation: 'Budidaya',
  npk: 'Form NPK',
  calendar: 'Kalender Budidaya',
  fertilizer: 'Pupuk',
};

const dropdownItem = routeMenu => {
  const defaultItem = [
    {label: 'Show', action: 'show'},
    {label: 'Edit', action: 'edit'},
  ];

  const growthItem = [
    {label: 'Add Data', action: 'add'},
    {label: 'Show Data', action: 'show'},
    {label: 'Add Image', action: 'image'},
    {label: 'Analytics', action: 'analytic'},
    {label: 'Heat Map', action: 'heatmap'},
  ];

  switch (routeMenu) {
    case 'growth':
      return growthItem;
    default:
      return defaultItem;
  }
};

const Agriculture = () => {
  const token = useUserStore(state => state.token);
  const {page} = useParams();
  const [menu, setMenu] = useState('plant');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleFetch = async fetch => {
    if (!fetch) return;

    setData([]);
    setLoading(true);
    try {
      const response = await fetch(token);
      if (response) {
        setData(response);
      }
    } catch (error) {
      console.error(`error ${error}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setMenu(page);

    switch (page) {
      case 'plant':
        handleFetch(fetchPlant);
        break;
      case 'cultivation':
        handleFetch(fetchCultivation);
        break;
      case 'disease':
        handleFetch(fetchDisease);
        break;
      case 'article':
        handleFetch(fetchArticle);
        break;
      case 'growth':
        handleFetch(fetchGrowth);
        break;
      case 'fertilizer':
        handleFetch(fetchFertilizer);
        break;
      default:
        break;
    }
  }, [page]);

  const dropdownView = (routeMenu, routeId) => (
    <div>
      <Dropdown
        name="Action"
        url={`/agriculture/${routeMenu}/${routeId}`}
        items={dropdownItem(routeMenu)}
      />
    </div>
  );

  const articleView = () => {
    const articleColumns = [
      {
        Header: 'No',
        accessor: 'no',
        width: '10%',
        Cell: cellInfo => <span>{cellInfo.row.index + 1}</span>,
      },
      {
        Header: 'Judul',
        accessor: 'title',
        Filter: SelectColumnFilter,
        filter: 'includes',
        width: '50%',
      },
      {
        Header: 'Kategori',
        accessor: 'category',
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
      {
        Header: 'Tanggal',
        accessor: 'created_at',
        Cell: cellInfo => (
          <span>
            {moment(cellInfo.row.original.created_at).format('DD/MM/YYYY')}
          </span>
        ),
      },
      {
        Header: 'Action',
        Filter: false,
        disableSorting: true,
        Cell: cellInfo => dropdownView('article', cellInfo.row.original.id),
      },
    ];

    return (
      <>
        <div className="col-md-9">
          <div className="row row-deck row-cards">
            <div className="col-md-12">
              <Card styleBody={{padding: 0, paddingTop: 20}}>
                <HabibiTable
                  title="artikel"
                  columns={articleColumns}
                  data={data}
                  formOne={2}
                  formTwo={null}
                  formThree={null}
                  rowSize={[10, 30, 50]}
                />
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  };

  const diseaseView = () => {
    const diseaseColumns = [
      {
        Header: 'No',
        accessor: 'no',
        width: '10%',
        Cell: cellInfo => <span>{cellInfo.row.index + 1}</span>,
      },
      {
        Header: 'Gambar',
        accessor: 'img',
        width: '20%',
        Cell: cellInfo => {
          const image = `${newURI}/diseases/${cellInfo.row.original.img}`;
          return (
            <div style={{height: '70px', width: '70px', overflow: 'hidden'}}>
              <img src={image} alt="penyakit" height="auto" width="auto" />
            </div>
          );
        },
      },
      {
        Header: 'Nama Penyakit',
        accessor: 'name',
        Filter: SelectColumnFilter,
        filter: 'includes',
        width: '60%',
      },
      {
        Header: 'Action',
        Filter: false,
        disableSorting: true,
        Cell: cellInfo => dropdownView('disease', cellInfo.row.original.id),
      },
    ];

    return (
      <>
        <div className="col-md-9">
          <div className="row row-deck row-cards">
            <div className="col-md-12">
              <Card styleBody={{padding: 0, paddingTop: 20}}>
                <HabibiTable
                  title="Hama Penyakit"
                  columns={diseaseColumns}
                  data={data}
                  formOne={2}
                  formTwo={null}
                  formThree={null}
                  rowSize={[10, 30, 50]}
                />
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  };

  const growthView = () => {
    const growthColumns = [
      {
        Header: 'No',
        accessor: 'no',
        width: '10%',
        Cell: cellInfo => <span>{cellInfo.row.index + 1}</span>,
      },
      {
        Header: 'Tanaman',
        accessor: 'plant_name',
        Filter: SelectColumnFilter,
        filter: 'includes',
        width: '40%',
      },
      {
        Header: 'Kebun',
        accessor: 'farm_name',
        Filter: SelectColumnFilter,
        filter: 'includes',
        width: '40%',
      },
      {
        Header: 'Tgl Tanam',
        width: '40%',
        Filter: false,
        Cell: cellInfo => (
          <span>
            {moment(cellInfo.row.original.date_planted).format('DD/MM/YYYY')}
          </span>
        ),
      },
      {
        Header: 'Action',
        Filter: false,
        disableSorting: true,
        Cell: cellInfo => dropdownView('growth', cellInfo.row.original.id),
      },
    ];

    return (
      <>
        <div className="col-md-9">
          <div className="row row-deck row-cards">
            <div className="col-md-12">
              <Card styleBody={{padding: 0, paddingTop: 20}}>
                <HabibiTable
                  title="Data Pertumbuhan"
                  columns={growthColumns}
                  data={data}
                  formOne={1}
                  formTwo={2}
                  formThree={null}
                  rowSize={[10, 30, 50]}
                />
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  };

  const plantView = () => {
    const plantColumns = [
      {
        Header: 'No',
        accessor: 'no',
        width: '10%',
        Cell: cellInfo => <span>{cellInfo.row.index + 1}</span>,
      },
      {
        Header: 'Tanaman',
        accessor: 'plants_name',
        filter: 'includes',
        width: '30%',
      },
      {
        Header: 'Keluarga',
        accessor: 'family',
        Filter: SelectColumnFilter,
        filter: 'includes',
        width: '30%',
      },
      {
        Header: 'Genus',
        accessor: 'genus',
        Filter: SelectColumnFilter,
        filter: 'includes',
        width: '30%',
      },
      {
        Header: 'Action',
        Filter: false,
        disableSorting: true,
        Cell: cellInfo => dropdownView('plant', cellInfo.row.original.id),
      },
    ];

    return (
      <>
        <div className="col-md-9">
          <div className="row row-deck row-cards">
            <div className="col-md-12">
              <Card styleBody={{padding: 0, paddingTop: 20}}>
                {/* <p>{JSON.stringify(data)}</p> */}
                <HabibiTable
                  title="tanaman"
                  columns={plantColumns}
                  data={data}
                  formOne={2}
                  formTwo={3}
                  formThree={null}
                  rowSize={[10, 30, 50]}
                />
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  };

  const cultivationView = () => (
    <>
      <div className="col-md-9">
        <div className="col-md-12 text-center">
          <Card className="d-flex align-items-center py-1">
            <p className="text-red">Under Construction</p>
          </Card>
        </div>
      </div>
    </>
  );

  const npkView = () => (
    <div className="col-md-9">
      <div className="row">
        <Npk />
      </div>
    </div>
  );

  const calendarView = () => (
    <div className="col-md-9">
      <div className="col-md-12 text-center">
        <Card className="py-1 card-calendar">
          <FullCalendar
            initialView="dayGridMonth"
            selectable
            editable
            droppable
            plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
            headerToolbar={{
              start: 'prev,next today',
              center: 'title',
              end: 'dayGridMonth,listMonth',
            }}
            eventClick={event =>
              console.log({
                title: event.event.title,
              })
            }
            dateClick={event =>
              console.log({
                dateStr: event.dateStr,
              })
            }
            select={event =>
              console.log({
                startStr: event.startStr,
                endStr: event.endStr,
              })
            }
            eventDrop={event =>
              console.log({
                event: event.event.startStr,
              })
            }
            events={[
              {
                title: 'Tanam Benih',
                start: '2022-06-12T12:30:00',
                end: null,
                note: 'Catatan 1',
                codeFarm: 'KODE_1',
              },
              {
                title: 'Pemupukan Lahan',
                start: '2022-06-08T12:30:00',
                end: '2022-06-10T15:30:00',
                note: 'Catatan 1',
                codeFarm: 'KODE_2',
              },
            ]}
            locale="id"
          />
        </Card>
      </div>
    </div>
  );

  const fertilizerView = () => {
    const fertilizerColumns = [
      {
        Header: 'No',
        accessor: 'no',
        width: '10%',
        Cell: cellInfo => <span>{cellInfo.row.index + 1}</span>,
      },
      {
        Header: 'Nama Pupuk',
        accessor: 'name',
        width: '50%',
      },
      {
        Header: 'N',
        Cell: cellInfo => <span>{cellInfo.row.original.content?.N}</span>,
      },
      {
        Header: 'P',
        Cell: cellInfo => <span>{cellInfo.row.original.content?.P}</span>,
      },
      {
        Header: 'K',
        Cell: cellInfo => <span>{cellInfo.row.original.content?.K}</span>,
      },
      {
        Header: 'Action',
        Filter: false,
        disableSorting: true,
        Cell: cellInfo => dropdownView('fertilizer', cellInfo.row.original._id),
      },
    ];

    return (
      <>
        <div className="col-md-9">
          <div className="row row-deck row-cards">
            <div className="col-md-12">
              <Card styleBody={{padding: 0, paddingTop: 20}}>
                <HabibiTable
                  title="pupuk"
                  columns={fertilizerColumns}
                  data={data}
                  formOne={null}
                  formTwo={null}
                  formThree={null}
                  rowSize={[10, 30, 50]}
                />
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleView = () => {
    switch (menu) {
      case 'article':
        return articleView();

      case 'disease':
        return diseaseView();

      case 'growth':
        return growthView();

      case 'plant':
        return plantView();

      case 'cultivation':
        return cultivationView();

      case 'npk':
        return npkView();

      case 'calendar':
        return calendarView();

      case 'fertilizer':
        return fertilizerView();

      default:
        return null;
    }
  };

  const headerView = () => {
    const name = menus[menu] || '';

    return (
      <>
        <div className="page-header">
          <div className="row align-items-center">
            {/* Title */}
            <div className="col">
              <div className="page-pretitle">Menu</div>
              <h2 className="page-title">
                <span>Pertanian</span>
                <span className="mx-2"> / </span>
                <span>{name}</span>
              </h2>
            </div>

            {/* Actions Button */}
            {menu !== 'npk' && menu !== 'calendar' ? (
              <div className="col-auto ml-auto d-print-none">
                <Link
                  className="btn btn-gray"
                  to={`/agriculture/${menu}/create`}>
                  <div className="d-flex align-items-center">
                    <Icon
                      name="PlusCircle"
                      size={16}
                      style={{marginRight: 5}}
                    />{' '}
                    <span>Tambah {name}</span>
                  </div>
                </Link>
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  };

  const sidebarView = () => (
    <div className="col-md-3">
      <Route>
        <Card title="Menu" styleBody={{padding: '12px', textAlign: 'center'}}>
          {Object.keys(menus).map(m => (
            <Link
              key={m}
              to={`/agriculture/${m}`}
              className={`btn my-1 w-100 ${menu === m ? 'btn-blue' : ''}`}>
              {menus[m]}
            </Link>
          ))}
        </Card>
      </Route>
    </div>
  );

  return (
    <div className="container-xl">
      {headerView()}

      <div className="row row-cards">
        {sidebarView()}

        {/* content */}
        {loading ? (
          <div className="col-md-9">
            <LoadingView />
          </div>
        ) : (
          handleView()
        )}
      </div>
    </div>
  );
};

export default Agriculture;
