import React, {useState, useEffect, useCallback} from 'react';
import moment from 'moment';
import axios from 'axios';
import idx from 'idx';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import {CSVLink} from 'react-csv';
import Modal from 'react-modal';
import {newAPI, oldAPI} from '../../services/baseAPI';
import useUserStore from '../../stores/Users';
import Card from '../../components/SimpleCard';
import LoadingView from '../../components/Loading';
import './Report.css';

function Report() {
  /** State Section */
  const ADMIN = 1;
  const OWNER = 2;
  const GUEST = 5;
  const EMPLOYEES = 6 || 7;
  const token = useUserStore(state => state.token);
  const users = useUserStore(state => state.user);
  // const startDay = new Date();
  const endDay = new Date();
  const [startDate, onChangeStartDate] = useState(
    moment()
      .subtract(31, 'days')
      .set({
        hour: 0,
        minute: 0,
        second: 0,
      })
      .toDate()
  );
  const [endDate, onChangeEndDate] = useState(
    moment()
      .set({
        hour: 23,
        minute: 59,
        second: 59,
      })
      .toDate()
  );
  const [isLoading, setLoading] = useState(true);
  const [menu, setMenu] = useState('download_data');
  const [downloadBtn, setDownloadBtn] = useState(false);
  const [csvData, setCsvData] = useState([['Tanggal', 'Sensor']]);
  const [csvDataAvg, setCsvDataAvg] = useState([]);
  const [farm, setFarm] = useState(null);
  const [device, setDevice] = useState('');
  const [plants, setPlants] = useState([]);
  const [growth, setGrowth] = useState([]);
  const [dataGrowth, setDataGrowth] = useState({
    kebun: 'Habibigarden',
    tanaman: 'Emas',
    tanggal: '17-08-1945',
    data: 'data',
  });
  const [farmList, setFarmList] = useState([
    {value: 'KEBUN_1', label: 'Kebun 1'},
    {value: 'KEBUN_2', label: 'Kebun 2'},
    {value: 'KEBUN_3', label: 'Kebun 3'},
  ]);
  const [order, setOrder] = useState(null);
  const [orderList, setOrderList] = useState([
    {value: 'Order_1', label: 'Order 1'},
    {value: 'Order_2', label: 'Order 2'},
    {value: 'Order_3', label: 'Order 3'},
  ]);
  const [deviceList, setDeviceList] = useState([
    {value: 'main', label: 'Habibi Grow'},
    {value: 'dose', label: 'Habibi Dose'},
    {value: 'station', label: 'Habibi Station'},
  ]);
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      borderRadius: '5px',
    },
  };
  const [modalIsOpen, setIsOpen] = useState(false);

  Modal.setAppElement('#root');

  const fetchAdmin = useCallback(async () => {
    await newAPI
      .get('/v3/farms', {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then(res => {
        const tmpFarms = [];
        const farms = res.data.data.last_data_device;

        farms.forEach(item => {
          if (item.jenis_data === 'config') {
            tmpFarms.push({
              label: item.name,
              value: item.code_farm,
              id: item.id,
            });
          }
        });

        // sort ascending
        const sortFarms = tmpFarms.sort((a, b) => (a.label > b.label ? 1 : -1));
        setFarmList(sortFarms);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(`error ${error}`);
      });
  }, []);

  const fetchGrowthPlants = useCallback(async item => {
    setPlants([]);
    await newAPI
      .get(`/growth/${item.id}/plants`, {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then(res => {
        const plantsArray = [];
        res.data.forEach((e, i) => {
          plantsArray.push({
            label: e.plant_name,
            value: e.id,
            parameter: e.parameters,
            index: i,
          });
        });
        setPlants(plantsArray);
      })
      .catch(error => {
        console.log(`error ${error}`);
      });
  }, []);

  const fetchGrowthDownload = useCallback(async item => {
    setGrowth([]);
    await newAPI
      .get(`/growth/${item.value}/data`, {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then(res => {
        const growthArray = [];
        res.data.forEach((e, i) => {
          growthArray.push({
            label: moment(e.created_at).format('DD-MM-YYYY'),
            value: i,
            data: e.parameters_data,
          });
        });

        setGrowth(growthArray);
      })
      .catch(error => {
        console.log(`error ${error}`);
      });
  }, []);

  const fetchOrder = useCallback(async item => {
    setLoading(true);
    await newAPI
      .get(`/order`, {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then(res => {
        setOrderList(res.data);
      })
      .catch(error => {
        console.log(`error ${error}`);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(false);

    if (users.status === ADMIN) {
      fetchAdmin();
    } else {
      // fetchOwner();
    }
  }, []);

  useEffect(() => {
    if (menu === 'download_order') {
      fetchOrder();
    }
  }, [menu]);

  const handleDownload = async () => {
    setIsOpen(true);
    console.log('Start Processing');
    setDownloadBtn(false);
    const oneDay = 24 * 60 * 60 * 1000;
    const diffDays = Math.round(Math.abs((startDate - endDate) / oneDay));
    const from = `${moment.utc(startDate).format('YYYY-MM-DD HH:mm:ss')}`;
    const end = `${moment.utc(endDate).format('YYYY-MM-DD HH:mm:ss')}`;
    const growFormat = [
      [
        ' ',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 1',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 2',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 3',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Zona 4',
        'Seluruh Zona',
      ],
      [
        'Tanggal',
        'Suhu (°C)',
        'Th Temp Min (°C)',
        'Th Temp Max (°C)',
        'Kelembaban (%)',
        'Th Hum Min (%)',
        'Th Hum Max (%)',
        'Kadar Air',
        'Th Mois Min',
        'Th Mois Max',
        'Nutrisi',
        'Th Nutr Min',
        'Th Nutr Max',
        'Cahaya (Lux)',
        'Th Light Min (Lux)',
        'Th Light Max (Lux)',
        'Batre (V)',
        'Th Bat Min (V)',
        'Th Bat Max (V)',
        'Tekanan (hPa)',
        'PH Tanah',
        'Th pH Min',
        'Th pH Max',
        'Suhu Tanah (°C)',
        'Th SoilTemp Min (°C)',
        'Th SoilTemp Max (°C)',
        'N',
        'P',
        'K',
        'Suhu (°C)',
        'Th Temp Min (°C)',
        'Th Temp Max (°C)',
        'Kelembaban (%)',
        'Th Hum Min (%)',
        'Th Hum Max (%)',
        'Kadar Air',
        'Th Mois Min',
        'Th Mois Max',
        'Nutrisi',
        'Th Nutr Min',
        'Th Nutr Max',
        'Cahaya (Lux)',
        'Th Light Min (Lux)',
        'Th Light Max (Lux)',
        'Batre (V)',
        'Th Bat Min (V)',
        'Th Bat Max (V)',
        'Tekanan (hPa)',
        'PH Tanah',
        'Th pH Min',
        'Th pH Max',
        'Suhu Tanah (°C)',
        'Th SoilTemp Min (°C)',
        'Th SoilTemp Max (°C)',
        'N',
        'P',
        'K',
        'Suhu (°C)',
        'Th Temp Min (°C)',
        'Th Temp Max (°C)',
        'Kelembaban (%)',
        'Th Hum Min (%)',
        'Th Hum Max (%)',
        'Kadar Air',
        'Th Mois Min',
        'Th Mois Max',
        'Nutrisi',
        'Th Nutr Min',
        'Th Nutr Max',
        'Cahaya (Lux)',
        'Th Light Min (Lux)',
        'Th Light Max (Lux)',
        'Batre (V)',
        'Th Bat Min (V)',
        'Th Bat Max (V)',
        'Tekanan (hPa)',
        'PH Tanah',
        'Th pH Min',
        'Th pH Max',
        'Suhu Tanah (°C)',
        'Th SoilTemp Min (°C)',
        'Th SoilTemp Max (°C)',
        'N',
        'P',
        'K',
        'Suhu (°C)',
        'Th Temp Min (°C)',
        'Th Temp Max (°C)',
        'Kelembaban (%)',
        'Th Hum Min (%)',
        'Th Hum Max (%)',
        'Kadar Air',
        'Th Mois Min',
        'Th Mois Max',
        'Nutrisi',
        'Th Nutr Min',
        'Th Nutr Max',
        'Cahaya (Lux)',
        'Th Light Min (Lux)',
        'Th Light Max (Lux)',
        'Batre (V)',
        'Th Bat Min (V)',
        'Th Bat Max (V)',
        'Tekanan (hPa)',
        'PH Tanah',
        'Th pH Min',
        'Th pH Max',
        'Suhu Tanah (°C)',
        'Th SoilTemp Min (°C)',
        'Th SoilTemp Max (°C)',
        'N',
        'P',
        'K',
        'Relay',
      ],
    ];
    const doseFormat = [
      [
        'Tanggal',
        'Pump',
        'Pemupukan',
        'TDS',
        'pH',
        'Suhu (°C)',
        'Pump Flow',
        'Flow Pupuk',
        'CF',
        'Slope',
        'Intercept',
        'PPM',
        'Relay',
      ],
    ];
    const stationFormat = [
      [
        'Tanggal',
        'Arah Angin (°)',
        'Kecepatan Angin (m/s)',
        'Hembusan Angin (m/s)',
        'Hujan Per Jam (mm/jam)',
        'Hujan Per Hari',
        'Akumulasi Hujan',
        'Suhu (°C)',
        'Kelembaban (%)',
        'Tekanan (hPa)',
        'Volt Batre (V)',
        'Arus Batre (mA)',
        'Volt Solar (V)',
        'Arus Solar (mA)',
        'UV',
        'Solar',
        'Cahaya (Lux)',
      ],
    ];
    const avgFormat = [];
    const maxFormat = [];
    const minFormat = [];
    const arrayGrand = [];
    const arrayGrandMin = [];
    const arrayGrandMax = [];
    console.log(from);
    console.log(end);
    if (diffDays <= 31) {
      await newAPI
        .get(`/v3/analytic/${farm.value}/${device}/${from}/${end}`)
        .then(res => {
          // Format Date Grow
          if (device === 'main') {
            const count = Object.keys(JSON.parse(res.data[0].data).zona).length;
            const sensorAvg = [];
            const arrayTmp = [];
            const arrayTmpZone = [];
            const arrayMinTmpZone = [];
            const arrayMaxTmpZone = [];

            const zeroArray = [];
            let arrayTotal = [];
            let arrayAvgTemp = [];
            let increment = [];
            let numberAvgTemp = [];
            let dateNow = moment(res.data[0].time).format('DD/MM/YYYY');
            arrayTmp.push('Tanggal');
            arrayTmpZone.push('');
            arrayMinTmpZone.push('Tanggal');
            arrayMaxTmpZone.push('Tanggal');
            for (let i = 1; i <= count; i += 1) {
              arrayTmpZone.push(
                `Zona ${i}`,
                `Zona ${i}`,
                `Zona ${i}`,
                `Zona ${i}`,
                `Zona ${i}`,
                `Zona ${i}`,
                `Zona ${i}`,
                `Zona ${i}`,
                `Zona ${i}`,
                `Zona ${i}`,
                `Zona ${i}`,
                `Zona ${i}`
              );
              arrayTmp.push(
                'Average of Suhu (°C)',
                'Average of Kelembaban (%)',
                'Average of Kadar Air',
                'Average of Nutrisi',
                'Average of Cahaya (Lux)',
                'Average of Batre (V)',
                'Average of Tekanan (hPa)',
                'Average of PH Tanah',
                'Average of Suhu Tanah (°C)',
                'Average of N',
                'Average of P',
                'Average of K'
              );
              arrayMinTmpZone.push(
                'Min of Suhu (°C)',
                'Min of Kelembaban (%)',
                'Min of Kadar Air',
                'Min of Nutrisi',
                'Min of Cahaya (Lux)',
                'Min of Batre (V)',
                'Min of Tekanan (hPa)',
                'Min of PH Tanah',
                'Min of Suhu Tanah (°C)',
                'Min of N',
                'Min of P',
                'Min of K'
              );
              arrayMaxTmpZone.push(
                'Max of Suhu (°C)',
                'Max of Kelembaban (%)',
                'Max of Kadar Air',
                'Max of Nutrisi',
                'Max of Cahaya (Lux)',
                'Max of Batre (V)',
                'Max of Tekanan (hPa)',
                'Max of PH Tanah',
                'Max of Suhu Tanah (°C)',
                'Max of N',
                'Max of P',
                'Max of K'
              );

              arrayAvgTemp.push(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
              increment.push(0);
            }
            for (let j = 0; j < 12 * count; j += 1) {
              zeroArray.push(0);
            }

            avgFormat.push(arrayTmpZone, arrayTmp);
            minFormat.push(arrayTmpZone, arrayMinTmpZone);
            maxFormat.push(arrayTmpZone, arrayMaxTmpZone);

            res.data.forEach((item, k, arr) => {
              const sensor = JSON.parse(item.data);
              const sensorZone = [];
              const dateTmp = moment(item.time).format('DD/MM/YYYY');
              sensorZone.push(moment(item.time).format('DD-MM-YYYY HH:mm:ss'));
              // olah data avg,min,max
              if (dateNow === dateTmp) {
                numberAvgTemp = [];
                const numberMMTemp = [];
                for (let i = 1; i <= count; i += 1) {
                  const numberTemp = [];
                  numberTemp.push(
                    idx(sensor, _ => _.zona[i.toString()].sensor.temp)
                      ? parseFloat(sensor.zona[i.toString()].sensor.temp)
                      : 0,
                    idx(sensor, _ => _.zona[i.toString()].sensor.hum)
                      ? parseFloat(sensor.zona[i.toString()].sensor.hum)
                      : 0,
                    idx(sensor, _ => _.zona[i.toString()].sensor.mois)
                      ? parseFloat(sensor.zona[i.toString()].sensor.mois)
                      : 0,
                    idx(sensor, _ => _.zona[i.toString()].sensor.nutr)
                      ? parseFloat(sensor.zona[i.toString()].sensor.nutr)
                      : 0,
                    idx(sensor, _ => _.zona[i.toString()].sensor.light)
                      ? parseFloat(sensor.zona[i.toString()].sensor.light)
                      : 0,
                    idx(sensor, _ => _.zona[i.toString()].sensor.batre)
                      ? parseFloat(sensor.zona[i.toString()].sensor.batre)
                      : 0,
                    idx(sensor, _ => _.zona[i.toString()].sensor.pressure)
                      ? parseFloat(sensor.zona[i.toString()].sensor.pressure)
                      : 0,
                    idx(sensor, _ => _.zona[i.toString()].sensor.soilph)
                      ? parseFloat(sensor.zona[i.toString()].sensor.soilph)
                      : 0,
                    idx(sensor, _ => _.zona[i.toString()].sensor.soilt)
                      ? parseFloat(sensor.zona[i.toString()].sensor.soilt)
                      : 0,
                    idx(sensor, _ => _.zona[i.toString()].sensor.N)
                      ? parseFloat(sensor.zona[i.toString()].sensor.N)
                      : 0,
                    idx(sensor, _ => _.zona[i.toString()].sensor.P)
                      ? parseFloat(sensor.zona[i.toString()].sensor.P)
                      : 0,
                    idx(sensor, _ => _.zona[i.toString()].sensor.K)
                      ? parseFloat(sensor.zona[i.toString()].sensor.K)
                      : 0
                  );

                  if (numberTemp.filter(x => x === 0).length < 9) {
                    numberAvgTemp.push(
                      ...numberTemp.map(e => (Number.isNaN(e) ? 0 : e))
                    );
                    numberMMTemp.push(...numberTemp);
                    increment[i - 1] += 1;
                  } else {
                    numberAvgTemp.push(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
                  }
                }

                arrayTotal.push(numberMMTemp);

                for (let j = 0; j < 12 * count; j += 1) {
                  arrayAvgTemp[j] += numberAvgTemp[j];
                }
              } else {
                const max = [];
                const min = [];

                for (let i = 0; i < arrayTotal.length; i += 1) {
                  if (
                    JSON.stringify(arrayTotal[i]) === JSON.stringify(zeroArray)
                  ) {
                    arrayTotal.splice(i, 1);
                  }
                }
                for (let i = 1; i <= count; i += 1) {
                  for (let j = 0; j < 12 * i; j += 1) {
                    if (increment[i - 1] !== 0) {
                      arrayAvgTemp[j + 12 * (i - 1)] /= increment[i - 1];
                    }
                  }
                }
                for (let i = 0; i < 12 * count; i += 1) {
                  const minArray = arrayTotal.map(e => e[i]);
                  const filterZero = minArray.filter(e => e > 0);
                  max.push(
                    Math.max(...(filterZero.length === 0 ? [0] : filterZero))
                  );
                  min.push(
                    Math.min(...(filterZero.length === 0 ? [0] : filterZero))
                  );
                }
                minFormat.push([dateNow, ...min]);
                maxFormat.push([dateNow, ...max]);
                avgFormat.push([
                  dateNow,
                  ...arrayAvgTemp.map(e => e.toFixed(2)),
                ]);
                const numberMMTemp = [];
                increment = [];
                arrayAvgTemp = [];
                numberAvgTemp = [];
                arrayTotal = [];
                dateNow = dateTmp;
                for (let i = 1; i <= count; i += 1) {
                  arrayAvgTemp.push(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
                  increment.push(0);
                }
                for (let i = 1; i <= count; i += 1) {
                  const numberTemp = [];
                  numberTemp.push(
                    idx(sensor, _ => _.zona[i.toString()].sensor.temp)
                      ? parseFloat(sensor.zona[i.toString()].sensor.temp)
                      : 0,
                    idx(sensor, _ => _.zona[i.toString()].sensor.hum)
                      ? parseFloat(sensor.zona[i.toString()].sensor.hum)
                      : 0,
                    idx(sensor, _ => _.zona[i.toString()].sensor.mois)
                      ? parseFloat(sensor.zona[i.toString()].sensor.mois)
                      : 0,
                    idx(sensor, _ => _.zona[i.toString()].sensor.nutr)
                      ? parseFloat(sensor.zona[i.toString()].sensor.nutr)
                      : 0,
                    idx(sensor, _ => _.zona[i.toString()].sensor.light)
                      ? parseFloat(sensor.zona[i.toString()].sensor.light)
                      : 0,
                    idx(sensor, _ => _.zona[i.toString()].sensor.batre)
                      ? parseFloat(sensor.zona[i.toString()].sensor.batre)
                      : 0,
                    idx(sensor, _ => _.zona[i.toString()].sensor.pressure)
                      ? parseFloat(sensor.zona[i.toString()].sensor.pressure)
                      : 0,
                    idx(sensor, _ => _.zona[i.toString()].sensor.soilph)
                      ? parseFloat(sensor.zona[i.toString()].sensor.soilph)
                      : 0,
                    idx(sensor, _ => _.zona[i.toString()].sensor.soilt)
                      ? parseFloat(sensor.zona[i.toString()].sensor.soilt)
                      : 0,
                    idx(sensor, _ => _.zona[i.toString()].sensor.N)
                      ? parseFloat(sensor.zona[i.toString()].sensor.N)
                      : 0,
                    idx(sensor, _ => _.zona[i.toString()].sensor.P)
                      ? parseFloat(sensor.zona[i.toString()].sensor.P)
                      : 0,
                    idx(sensor, _ => _.zona[i.toString()].sensor.K)
                      ? parseFloat(sensor.zona[i.toString()].sensor.K)
                      : 0
                  );
                  if (numberTemp.filter(x => x === 0).length < 9) {
                    numberAvgTemp.push(
                      ...numberTemp.map(e => (Number.isNaN(e) ? 0 : e))
                    );
                    increment[i - 1] += 1;
                  } else {
                    numberAvgTemp.push(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
                  }
                  numberMMTemp.push(...numberTemp);
                }
                arrayTotal.push(numberMMTemp);

                for (let j = 0; j < 12 * count; j += 1) {
                  arrayAvgTemp[j] += numberAvgTemp[j];
                }
              }

              if (k === arr.length - 1) {
                const max = [];
                const min = [];
                for (let i = 0; i < arrayTotal.length; i += 1) {
                  if (
                    JSON.stringify(arrayTotal[i]) === JSON.stringify(zeroArray)
                  ) {
                    arrayTotal.splice(i, 1);
                  }
                }

                for (let i = 1; i <= count; i += 1) {
                  for (let j = 0; j < 12; j += 1) {
                    if (increment[i - 1] !== 0) {
                      arrayAvgTemp[j + 12 * (i - 1)] /= increment[i - 1];
                    }
                  }
                }

                for (let i = 0; i < 12 * count; i += 1) {
                  const minArray = arrayTotal.map(e => e[i]);
                  const filterZero = minArray.filter(e => e > 0);
                  max.push(
                    Math.max(...(filterZero.length === 0 ? [0] : filterZero))
                  );
                  min.push(
                    Math.min(...(filterZero.length === 0 ? [0] : filterZero))
                  );
                }
                minFormat.push([dateNow, ...min]);
                maxFormat.push([dateNow, ...max]);
                avgFormat.push([
                  dateNow,
                  ...arrayAvgTemp.map(e => e.toFixed(2)),
                ]);

                for (let j = 1; j <= 12 * count; j += 1) {
                  let totalGrand = 0;
                  const totalMin = [];
                  const totalMax = [];
                  for (let i = 2; i < avgFormat.length; i += 1) {
                    totalGrand += parseFloat(avgFormat[i][j]);
                    totalMin.push(parseFloat(minFormat[i][j]));
                    totalMax.push(parseFloat(maxFormat[i][j]));
                  }
                  arrayGrand.push(totalGrand / (avgFormat.length - 2));

                  arrayGrandMin.push(Math.min(...totalMin));
                  arrayGrandMax.push(Math.max(...totalMax));
                }

                avgFormat.push([
                  'Grand Total',
                  ...arrayGrand.map(e => e.toFixed(2)),
                ]);
                minFormat.push(['Grand Total', ...arrayGrandMin]);
                maxFormat.push(['Grand Total', ...arrayGrandMax]);
              }

              for (let i = 1; i <= 4; i += 1) {
                sensorZone.push(
                  idx(sensor, _ => _.zona[i.toString()].sensor.temp)
                    ? sensor.zona[i.toString()].sensor.temp
                    : 0,
                  idx(sensor, _ => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(';')[1]
                        .toString()
                        .split('-')[0]
                    : '-',
                  idx(sensor, _ => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(';')[1]
                        .toString()
                        .split('-')[1]
                    : '-',
                  idx(sensor, _ => _.zona[i.toString()].sensor.hum)
                    ? sensor.zona[i.toString()].sensor.hum
                    : 0,
                  idx(sensor, _ => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(';')[2]
                        .toString()
                        .split('-')[0]
                    : '-',
                  idx(sensor, _ => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(';')[2]
                        .toString()
                        .split('-')[1]
                    : '-',
                  idx(sensor, _ => _.zona[i.toString()].sensor.mois)
                    ? sensor.zona[i.toString()].sensor.mois
                    : 0,
                  idx(sensor, _ => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(';')[8]
                        .toString()
                        .split('-')[0]
                    : '-',
                  idx(sensor, _ => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(';')[8]
                        .toString()
                        .split('-')[1]
                    : '-',
                  idx(sensor, _ => _.zona[i.toString()].sensor.nutr)
                    ? sensor.zona[i.toString()].sensor.nutr
                    : 0,
                  idx(sensor, _ => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(';')[3]
                        .toString()
                        .split('-')[0]
                    : '-',
                  idx(sensor, _ => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(';')[3]
                        .toString()
                        .split('-')[1]
                    : '-',
                  idx(sensor, _ => _.zona[i.toString()].sensor.light)
                    ? sensor.zona[i.toString()].sensor.light
                    : 0,
                  idx(sensor, _ => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(';')[4]
                        .toString()
                        .split('-')[0]
                    : '-',
                  idx(sensor, _ => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(';')[4]
                        .toString()
                        .split('-')[1]
                    : '-',
                  idx(sensor, _ => _.zona[i.toString()].sensor.batre)
                    ? sensor.zona[i.toString()].sensor.batre
                    : 0,
                  idx(sensor, _ => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(';')[6]
                        .toString()
                        .split('-')[0]
                    : '-',
                  idx(sensor, _ => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(';')[6]
                        .toString()
                        .split('-')[1]
                    : '-',
                  idx(sensor, _ => _.zona[i.toString()].sensor.pressure)
                    ? sensor.zona[i.toString()].sensor.pressure
                    : 0,
                  idx(sensor, _ => _.zona[i.toString()].sensor.soilph)
                    ? sensor.zona[i.toString()].sensor.soilph
                    : 0,
                  idx(sensor, _ => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(';')[5]
                        .toString()
                        .split('-')[0]
                    : '-',
                  idx(sensor, _ => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(';')[5]
                        .toString()
                        .split('-')[1]
                    : '-',
                  idx(sensor, _ => _.zona[i.toString()].sensor.soilt)
                    ? sensor.zona[i.toString()].sensor.soilt
                    : 0,
                  idx(sensor, _ => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(';')[7]
                        .toString()
                        .split('-')[0]
                    : '-',
                  idx(sensor, _ => _.zona[i.toString()].th)
                    ? sensor.zona[i.toString()].th
                        .toString()
                        .split(';')[7]
                        .toString()
                        .split('-')[1]
                    : '-',
                  idx(sensor, _ => _.zona[i.toString()].sensor.N)
                    ? sensor.zona[i.toString()].sensor.N
                    : 0,
                  idx(sensor, _ => _.zona[i.toString()].sensor.P)
                    ? sensor.zona[i.toString()].sensor.P
                    : 0,
                  idx(sensor, _ => _.zona[i.toString()].sensor.K)
                    ? sensor.zona[i.toString()].sensor.K
                    : 0
                );
              }

              sensorZone.push(
                idx(sensor, _ => _.info.relay)
                  ? sensor.info.relay.toString()
                  : '-'
              );

              growFormat.push(sensorZone);
            });
            setCsvDataAvg({avg: avgFormat, min: minFormat, max: maxFormat});
            setCsvData(growFormat);
          }

          // Format Data Dose
          if (device === 'dose') {
            res.data.forEach(item => {
              const dose = JSON.parse(item.data);
              doseFormat.push([
                moment(item.time).format('DD-MM-YYYY HH:mm:ss'),
                idx(dose, _ => _.info.sts_pump) ? dose.info.sts_pump : '-',
                idx(dose, _ => _.info.fert_valve) ? 'ON' : 'OFF',
                idx(dose, _ => _.sensor.tds) ? dose.sensor.tds : 0,
                idx(dose, _ => _.sensor.ph) ? dose.sensor.ph : 0,
                idx(dose, _ => _.sensor.temp) ? dose.sensor.temp : 0,
                idx(dose, _ => _.setting.pump_flow)
                  ? dose.setting.pump_flow
                  : '-',
                idx(dose, _ => _.setting.fert_flow)
                  ? dose.setting.fert_flow
                  : '-',
                idx(dose, _ => _.setting.cf) ? dose.setting.cf : '-',
                idx(dose, _ => _.setting.slope) ? dose.setting.slope : '-',
                idx(dose, _ => _.setting.intercept)
                  ? dose.setting.intercept
                  : '-',
                idx(dose, _ => _.setting.ppm_pupuk)
                  ? dose.setting.ppm_pupuk
                  : '-',
                idx(dose, _ => _.info.relay) ? dose.info.relay : '-',
              ]);
            });

            setCsvData(doseFormat);
          }

          // Format Data Station
          if (device === 'station') {
            let arrayAvg = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            let arrayMM = [];
            let iteration = 0;

            const finalAvg = [
              [
                'Tanggal',
                'Average of Arah Angin (°)',
                'Average of Kecepatan Angin (m/s)',
                'Average of Hembusan Angin (m/s)',
                'Average of Hujan Per Jam (mm/jam)',
                'Average of Hujan Per Hari',
                'Average of Akumulasi Hujan',
                'Average of Suhu (°C)',
                'Average of Kelembaban (%)',
                'Average of Tekanan (hPa)',
                'Average of Volt Batre (V)',
                'Average of Arus Batre (mA)',
                'Average of Volt Solar (V)',
                'Average of Arus Solar (mA)',
                'Average of UV',
                'Average of Solar',
                'Average of Cahaya (Lux)',
              ],
            ];
            const finalMin = [
              [
                'Tanggal',
                'Min of Arah Angin (°)',
                'Min of Kecepatan Angin (m/s)',
                'Min of Hembusan Angin (m/s)',
                'Min of Hujan Per Jam (mm/jam)',
                'Min of Hujan Per Hari',
                'Min of Akumulasi Hujan',
                'Min of Suhu (°C)',
                'Min of Kelembaban (%)',
                'Min of Tekanan (hPa)',
                'Min of Volt Batre (V)',
                'Min of Arus Batre (mA)',
                'Min of Volt Solar (V)',
                'Min of Arus Solar (mA)',
                'Min of UV',
                'Min of Solar',
                'Min of Cahaya (Lux)',
              ],
            ];
            const finalMax = [
              [
                'Tanggal',
                'Max of Arah Angin (°)',
                'Max of Kecepatan Angin (m/s)',
                'Max of Hembusan Angin (m/s)',
                'Max of Hujan Per Jam (mm/jam)',
                'Max of Hujan Per Hari',
                'Max of Akumulasi Hujan',
                'Max of Suhu (°C)',
                'Max of Kelembaban (%)',
                'Max of Tekanan (hPa)',
                'Max of Volt Batre (V)',
                'Max of Arus Batre (mA)',
                'Max of Volt Solar (V)',
                'Max of Arus Solar (mA)',
                'Max of UV',
                'Max of Solar',
                'Max of Cahaya (Lux)',
              ],
            ];
            res.data.forEach(item => {
              const dataStation = [];
              if (item.data.toString().charAt(0) === '{') {
                const station = JSON.parse(item.data);
                // console.log(station);
                dataStation.push(
                  moment(item.time).format('DD-MM-YYYY HH:mm:ss').toString(),
                  idx(station, _ => _.sensor.windDirection)
                    ? station.sensor.windDirection.toString()
                    : '-',
                  idx(station, _ => _.sensor.windSpeedAverage)
                    ? station.sensor.windSpeedAverage.toString()
                    : '-',
                  idx(station, _ => _.sensor.windSpeedMax)
                    ? station.sensor.windSpeedMax.toString()
                    : '-',
                  idx(station, _ => _.sensor.rainFallOneHour)
                    ? station.sensor.rainFallOneHour.toString()
                    : '-',
                  idx(station, _ => _.sensor.rainFallOneDay)
                    ? station.sensor.rainFallOneDay.toString()
                    : '-',
                  idx(station, _ => _.sensor.recordRain)
                    ? station.sensor.recordRain.toString()
                    : '-',
                  idx(station, _ => _.sensor.temperature)
                    ? station.sensor.temperature.toString()
                    : '-',
                  idx(station, _ => _.sensor.humidity)
                    ? station.sensor.humidity.toString()
                    : '-',
                  idx(station, _ => _.sensor.pressure)
                    ? station.sensor.pressure.toString()
                    : '-',
                  idx(station, _ => _.sensor.voltBatt)
                    ? station.sensor.voltBatt.toString()
                    : '-',
                  idx(station, _ => _.sensor.currBatt)
                    ? station.sensor.currBatt.toString()
                    : '-',
                  idx(station, _ => _.sensor.voltSolar)
                    ? station.sensor.voltSolar.toString()
                    : '-',
                  idx(station, _ => _.sensor.currSolar)
                    ? station.sensor.currSolar.toString()
                    : '-',
                  idx(station, _ => _.sensor.uv)
                    ? station.sensor.uv.toString()
                    : '-',
                  idx(station, _ => _.sensor.solar)
                    ? station.sensor.solar.toString()
                    : '-',
                  idx(station, _ => _.sensor.light)
                    ? station.sensor.light.toString()
                    : '-'
                );
              } else {
                const station = item.data.split(',');
                dataStation.push(
                  moment(item.time).format('DD-MM-YYYY HH:mm:ss')
                );
                for (let i = 0; i < 15; i += 1) {
                  dataStation.push(
                    idx(station, _ => _[i]) ? station[i].toString() : '-'
                  );
                }
              }

              stationFormat.push(dataStation);
            });
            setCsvData(stationFormat);
            // ----------------
            let dateNow = stationFormat[1][0].slice(0, 10);

            stationFormat.forEach((g, k, arr) => {
              if (k > 0) {
                const dateTmp = g[0].slice(0, 10);
                if (dateNow === dateTmp) {
                  arrayMM.push([
                    g[1] === '-' ? 0 : parseFloat(g[1]),
                    g[2] === '-' ? 0 : parseFloat(g[2]),
                    g[3] === '-' ? 0 : parseFloat(g[3]),
                    g[4] === '-' ? 0 : parseFloat(g[4]),
                    g[5] === '-' ? 0 : parseFloat(g[5]),
                    g[6] === '-' ? 0 : parseFloat(g[6]),
                    g[7] === '-' ? 0 : parseFloat(g[7]),
                    g[8] === '-' ? 0 : parseFloat(g[8]),
                    g[9] === '-' ? 0 : parseFloat(g[9]),
                    g[10] === '-' ? 0 : parseFloat(g[10]),
                    g[11] === '-' ? 0 : parseFloat(g[11]),
                    g[12] === '-' ? 0 : parseFloat(g[12]),
                    g[13] === '-' ? 0 : parseFloat(g[13]),
                    g[14] === '-' ? 0 : parseFloat(g[14]),
                    g[15] === '-' ? 0 : parseFloat(g[15]),
                    g[16] === '-' ? 0 : parseFloat(g[16]),
                  ]);
                  arrayAvg = [
                    g[1] === '-' ? 0 : (arrayAvg[0] += parseFloat(g[1])),
                    g[2] === '-' ? 0 : (arrayAvg[1] += parseFloat(g[2])),
                    g[3] === '-' ? 0 : (arrayAvg[2] += parseFloat(g[3])),
                    g[4] === '-' ? 0 : (arrayAvg[3] += parseFloat(g[4])),
                    g[5] === '-' ? 0 : (arrayAvg[4] += parseFloat(g[5])),
                    g[6] === '-' ? 0 : (arrayAvg[5] += parseFloat(g[6])),
                    g[7] === '-' ? 0 : (arrayAvg[6] += parseFloat(g[7])),
                    g[8] === '-' ? 0 : (arrayAvg[7] += parseFloat(g[8])),
                    g[9] === '-' ? 0 : (arrayAvg[8] += parseFloat(g[9])),
                    g[10] === '-' ? 0 : (arrayAvg[9] += parseFloat(g[10])),
                    g[11] === '-' ? 0 : (arrayAvg[10] += parseFloat(g[11])),
                    g[12] === '-' ? 0 : (arrayAvg[11] += parseFloat(g[12])),
                    g[13] === '-' ? 0 : (arrayAvg[12] += parseFloat(g[13])),
                    g[14] === '-' ? 0 : (arrayAvg[13] += parseFloat(g[14])),
                    g[15] === '-' ? 0 : (arrayAvg[14] += parseFloat(g[15])),
                    g[16] === '-' ? 0 : (arrayAvg[15] += parseFloat(g[16])),
                  ];
                  iteration += 1;
                  // console.log(arrayAvg);
                } else {
                  finalAvg.push([
                    dateNow,
                    ...arrayAvg.map(f => (f / iteration).toFixed(2)),
                  ]);
                  const max = [];
                  const min = [];
                  for (let i = 0; i <= 15; i += 1) {
                    const minArray = arrayMM.map(f => f[i]);
                    max.push(
                      Math.max(...(minArray.length === 0 ? [0] : minArray))
                    );
                    min.push(
                      Math.min(...(minArray.length === 0 ? [0] : minArray))
                    );
                  }
                  finalMin.push([dateNow, ...min]);
                  finalMax.push([dateNow, ...max]);
                  arrayMM = [];
                  arrayAvg = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                  dateNow = dateTmp;
                  iteration = 0;

                  arrayMM.push([
                    g[1] === '-' ? 0 : parseFloat(g[1]),
                    g[2] === '-' ? 0 : parseFloat(g[2]),
                    g[3] === '-' ? 0 : parseFloat(g[3]),
                    g[4] === '-' ? 0 : parseFloat(g[4]),
                    g[5] === '-' ? 0 : parseFloat(g[5]),
                    g[6] === '-' ? 0 : parseFloat(g[6]),
                    g[7] === '-' ? 0 : parseFloat(g[7]),
                    g[8] === '-' ? 0 : parseFloat(g[8]),
                    g[9] === '-' ? 0 : parseFloat(g[9]),
                    g[10] === '-' ? 0 : parseFloat(g[10]),
                    g[11] === '-' ? 0 : parseFloat(g[11]),
                    g[12] === '-' ? 0 : parseFloat(g[12]),
                    g[13] === '-' ? 0 : parseFloat(g[13]),
                    g[14] === '-' ? 0 : parseFloat(g[14]),
                    g[15] === '-' ? 0 : parseFloat(g[15]),
                    g[16] === '-' ? 0 : parseFloat(g[16]),
                  ]);
                  arrayAvg = [
                    g[1] === '-' ? 0 : (arrayAvg[0] += parseFloat(g[1])),
                    g[2] === '-' ? 0 : (arrayAvg[1] += parseFloat(g[2])),
                    g[3] === '-' ? 0 : (arrayAvg[2] += parseFloat(g[3])),
                    g[4] === '-' ? 0 : (arrayAvg[3] += parseFloat(g[4])),
                    g[5] === '-' ? 0 : (arrayAvg[4] += parseFloat(g[5])),
                    g[6] === '-' ? 0 : (arrayAvg[5] += parseFloat(g[6])),
                    g[7] === '-' ? 0 : (arrayAvg[6] += parseFloat(g[7])),
                    g[8] === '-' ? 0 : (arrayAvg[7] += parseFloat(g[8])),
                    g[9] === '-' ? 0 : (arrayAvg[8] += parseFloat(g[9])),
                    g[10] === '-' ? 0 : (arrayAvg[9] += parseFloat(g[10])),
                    g[11] === '-' ? 0 : (arrayAvg[10] += parseFloat(g[11])),
                    g[12] === '-' ? 0 : (arrayAvg[11] += parseFloat(g[12])),
                    g[13] === '-' ? 0 : (arrayAvg[12] += parseFloat(g[13])),
                    g[14] === '-' ? 0 : (arrayAvg[13] += parseFloat(g[14])),
                    g[15] === '-' ? 0 : (arrayAvg[14] += parseFloat(g[15])),
                    g[16] === '-' ? 0 : (arrayAvg[15] += parseFloat(g[16])),
                  ];
                  iteration += 1;
                }

                if (k === arr.length - 1) {
                  finalAvg.push([
                    dateNow,
                    ...arrayAvg.map(f => (f / iteration).toFixed(2)),
                  ]);
                  const max = [];
                  const min = [];
                  for (let i = 0; i <= 15; i += 1) {
                    const minArray = arrayMM.map(f => f[i]);
                    max.push(
                      Math.max(...(minArray.length === 0 ? [0] : minArray))
                    );
                    min.push(
                      Math.min(...(minArray.length === 0 ? [0] : minArray))
                    );
                  }
                  finalMin.push([dateNow, ...min]);
                  finalMax.push([dateNow, ...max]);

                  for (let j = 1; j <= 16; j += 1) {
                    let totalGrand = 0;
                    const totalMin = [];
                    const totalMax = [];
                    for (let i = 1; i < finalAvg.length; i += 1) {
                      totalGrand += parseFloat(finalAvg[i][j]);
                      totalMin.push(parseFloat(finalMin[i][j]));
                      totalMax.push(parseFloat(finalMax[i][j]));
                    }
                    arrayGrand.push(totalGrand / (finalAvg.length - 1));

                    arrayGrandMin.push(Math.min(...totalMin));
                    arrayGrandMax.push(Math.max(...totalMax));
                  }

                  finalAvg.push([
                    'Grand Total',
                    ...arrayGrand.map(e => e.toFixed(2)),
                  ]);
                  finalMin.push(['Grand Total', ...arrayGrandMin]);
                  finalMax.push(['Grand Total', ...arrayGrandMax]);
                }
              }
            });
            setCsvDataAvg({avg: finalAvg, min: finalMin, max: finalMax});
          }

          setDownloadBtn(true);
          setIsOpen(false);
          console.log('Sukses');
        })
        .catch(error => {
          setIsOpen(false);
          console.log('Err: ', error);
        });
    } else {
      // alert('tanggal tidak boleh lebih dari 31 hari');
    }
  };

  const handleDownloadGrowth = async () => {
    // console.log(dataGrowth);
    const array = [];
    array.push(['No', ...JSON.parse(dataGrowth.tanaman.parameter)]);
    JSON.parse(dataGrowth.data).forEach((e, j) => {
      array.push([j + 1, ...e.map(x => parseInt(x, 10))]);
    });
    if (array) {
      setDownloadBtn(true);
      setCsvData(array);
    }
  };

  const handleDownloadFarm = async () => {
    // console.log(dataGrowth);
    // setDownloadBtn(false);
    // setLoading(true);
    const array = farm.map(e => e.id);
    console.log(array.join());
    await newAPI
      .get(`v3/farms/'${array.join()}'/download/`, {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then(res => {
        console.log(res);
      })
      .catch(error => {
        console.log(`error ${error}`);
      });
  };

  const handleDownloadOrder = async () => {
    const arraySolutions = [
      {
        id: '1',
        nama: 'Habibi Grow (Up to 4 Zona)',
        kode: 'HG',
        uom: 'Unit',
        harga: '8000000',
        cicil: '450000',
        sewa: '100000',
      },
      {
        id: '2',
        nama: 'Habibi Dose',
        kode: 'HD',
        uom: 'Pack',
        harga: '12000000',
        cicil: '150000',
        sewa: '150000',
      },
      {
        id: '3',
        nama: 'Habibi Cooling System Indoor',
        kode: 'HC',
        uom: 'Pack',
        harga: '3000000',
        cicil: '200000',
        sewa: '50000',
      },
      {
        id: '4',
        nama: 'Habibi Climate Station (1-10 HA)',
        kode: 'HCS',
        uom: 'Pack',
        harga: '17000000',
        cicil: '950000',
        sewa: '400000',
      },
      {
        id: '5',
        nama: 'Habibi Climate Sensor Pro',
        kode: 'HCS',
        uom: 'Unit',
        harga: '5500000',
        cicil: '300000',
        sewa: '50000',
      },
      {
        id: '6',
        nama: 'Habibi Cam',
        kode: 'HCI',
        uom: 'Pack',
        harga: '5500000',
        cicil: '200000',
        sewa: '25000',
      },
      {
        id: '7',
        nama: 'Habibi Link',
        kode: 'HL',
        uom: 'Pack',
        harga: '3000000',
        cicil: '150000',
        sewa: '25000',
      },
      {
        id: '8',
        nama: 'Habibi Nutrition Pro (NPK Include pH)',
        kode: 'HNP',
        uom: 'Pack',
        harga: '6000000',
        cicil: '350000',
        sewa: '150000',
      },
      {
        id: '9',
        nama: 'Habibi Water Quality',
        kode: 'HWQ',
        uom: 'Unit',
        harga: '4500000',
        cicil: '250000',
        sewa: '100000',
      },
    ];
    const array = [
      [
        'Cust ID',
        'Quote',
        'Nama',
        'E-mail',
        'Telepon',
        'Mengetahui Habibi Garden dari',
        'Nama Asal Instansi/Perusahaan/Kelompok Tani',
        'Nama Kebun',
        'Alamat Kebun Budidaya Tanaman',
        'Tipe Lokasi Budidaya Tanaman',
        'Jenis Mekanikal Irigasi yang diinginkan pada Kebun Budidaya',
        'Luas Lahan Budidaya Tanaman (m² atau Ha)',
        'Panjang Lahan (m)',
        'Lebar Lahan (m)',
        'Jenis Tanaman Budidaya atau Komoditas Utama',
        'Lama budidaya dalam 1 musim (bulan)',
        'Media Tanam Budidaya tanaman',
        'Jarak antar Tanaman dalam satu bedengan (centimeter)',
        'Jumlah Bedengan',
        'Panjang Bedengan',
        'Jarak antar Bedengan (centimeter)',
        'Populasi Tanaman (10.000 Tanaman) / Luasan Lahan',
        'Jumlah Anggota Kelompok Tani',
        'Target Pemasaran',
        'Frekuensi Penyiraman dalam sehari',
        'Jenis Pupuk yang digunakan',
        'Permasalahan Budidaya Tanaman',
        'Sumber Listrik pada Kebun Budidaya',
        'Sumber Air Terdekat pada Kebun Budidaya',
        'Jenis Sumber Air Lahan Budidaya',
        'Provider Internet yang tersedia',
        'Ketinggian Lahan (mdpl)',
        'Kondisi Kemiringan Lahan',
        'Akses menuju Kebun Budidaya',
        'Daya (Watt) Listrik yang tersedia pada Lahan Budidaya',
        'Metode Penyiraman Tanaman saat ini',
        'Logo Kebun/Instansi/Perusahaan/Kelompok Tani',
        'Sistem Pembayaran',
        'Hasil Akhir yang diharapkan',
        'Jenis Pekerjaan Rutin yang akan diotomatisasi yang diharapkam pada Kebun Budidaya',
        'Produk Habibi Garden yang dibutuhkan',
      ],
    ];
    const newArray = order.map(e => orderList.filter(f => f.id === e.value));
    newArray.forEach(e => {
      const alatsArray = JSON.parse(e[0].produk);
      let mekanikal;
      let mekanikalNow;
      let alats = 'Tidak Memilih Produk';
      switch (e[0].farm_solution_mechanical) {
        case 'ds':
          mekanikal = 'Drip Stick';
          break;
        case 'cl':
          mekanikal = 'Cooling';
          break;

        case 'dt':
          mekanikal = 'Drip Tape';

          break;

        case 'dscl':
          mekanikal = 'Drip Stick & Cooling';
          break;

        case 'dtcl':
          mekanikal = 'Drip Tape & Cooling';
          break;
        case 'sp':
          mekanikal = 'Sprinkler';
          break;

        default:
          break;
      }
      switch (e[0].farm_method_watering) {
        case 'ds':
          mekanikalNow = 'Drip Stick';
          break;
        case 'cl':
          mekanikalNow = 'Cooling';
          break;

        case 'dt':
          mekanikalNow = 'Drip Tape';

          break;

        case 'dscl':
          mekanikalNow = 'Drip Stick & Cooling';
          break;

        case 'dtcl':
          mekanikalNow = 'Drip Tape & Cooling';
          break;
        case 'sp':
          mekanikalNow = 'Sprinkler';
          break;

        default:
          break;
      }
      if (alatsArray !== null) {
        const newArrayAlats = alatsArray.map(f =>
          arraySolutions.filter(g => g.id === f)
        );
        alats = newArrayAlats.map(f => f[0].nama).join();
      }
      array.push([
        e[0].cust_id,
        e[0].quote,
        e[0].name,
        e[0].email,
        e[0].phone,
        e[0].info_origin,
        e[0].info_instation,
        e[0].farm_name,
        e[0].farm_address,
        e[0].farm_type_cultivation,
        mekanikal,
        e[0].farm_area,
        e[0].farm_lenght,
        e[0].farm_widht,
        e[0].farm_species_plant,
        e[0].farm_culture_month,
        e[0].farm_media_growth,
        e[0].farm_plant_between,
        e[0].farm_amount,
        e[0].farm_lenght_bedengan,
        e[0].farm_between,
        e[0].farm_population_plant,
        e[0].farm_farmer_total,
        e[0].farm_market_target,
        e[0].farm_watering_frequency,
        e[0].farm_type_fertilizer,
        e[0].farm_plant_problem,
        e[0].farm_condition_electricity,
        e[0].farm_condition_water_source,
        e[0].farm_condition_type_water_source,
        e[0].farm_condition_provider,
        e[0].farm_condition_elevation,
        e[0].farm_condition_slope,
        e[0].farm_condition_access,
        e[0].farm_condition_electricity_power,
        mekanikalNow,
        `https://habibigarden.com/dashboard/public/img/order/${e[0].farm_condition_logo}`,
        e[0].farm_solution_payment,
        e[0].farm_solution_result,
        e[0].farm_solution_routine,
        alats,
      ]);
      setDownloadBtn(true);
      setCsvData(array);
    });
  };

  const handleAnalytic = async () => {};

  const header = () => (
    <>
      <div className="page-header">
        <div className="row align-items-center">
          {/* Title */}
          <div className="col">
            <div className="page-pretitle">Menu</div>
            <h2 className="page-title">Laporan</h2>
          </div>

          {/* Actions Button */}
          {/* {users.status === ADMIN ?
              <div className='col-auto ml-auto d-print-none'>
                <button className={'btn btn-gray'} style={{marginLeft: 10}} onClick={() => alert('Kebun')}>              
                  <div className='d-flex align-items-center'><Icon name='Layers' size={16} style={{marginRight: 10}}/> <span>Umum</span></div>
                </button>
                <button className={'btn btn-gray'} style={{marginLeft: 10}} onClick={() => alert('Kebun')}>              
                  <div className='d-flex align-items-center'><Icon name='Calendar' size={16} style={{marginRight: 10}}/> <span>Agenda</span></div>
                </button>
                <button className={'btn btn-gray'} style={{marginLeft: 10}} onClick={() => alert('Kebun')}>              
                  <div className='d-flex align-items-center'><Icon name='List' size={16} style={{marginRight: 10}}/> <span>Daftar Project</span></div>
                </button>
              </div>
            : <div/>} */}
        </div>
      </div>
    </>
  );

  const sidebar = () => (
    <div className="col-md-3">
      <div className="row row-deck row-cards">
        {/* Menu */}
        <div className="col-md-12">
          <Card title="Menu Laporan">
            <>
              {/* Jenis */}
              <div className="col-12">
                <select
                  className="form-select form-control"
                  defaultValue={menu}
                  onChange={e => setMenu(e.target.value)}>
                  <option disabled>Pilih salah satu</option>
                  {/* <option value="analytic_data">Custom Data Analitik</option> */}
                  <option value="download_data">Download Data</option>
                  <option value="download_growth">Download Pertumbuhan</option>
                  <option value="download_farm">Download Data Kebun</option>
                  <option value="download_order">
                    Download Data Penawaran
                  </option>
                  {/* <option value="other">Lain-Lain</option> */}
                </select>
              </div>
            </>
          </Card>
        </div>
      </div>
    </div>
  );

  const analyticDataView = () => (
    <div className="col-md-9">
      <p>Under construction</p>
    </div>
  );

  const downloadDataView = () => (
    <div className="col-md-9">
      <Card title={menu === 'download_data' ? 'Download Data Sensor' : ''}>
        <div className="row row-decks row-cards">
          {/* Pilih Kebun */}
          <div className="col-md-6">
            <label htmlFor="true" className="form-label">
              Nama Kebun
            </label>
            <Select
              options={farmList}
              placeholder="Pilih kebun"
              onChange={e => setFarm(e)}
            />
            {/* {errors.title && (
              <div className="pt-1" style={{ color: 'red' }}>
                Pilih nama kebun
              </div>
            )} */}
          </div>

          {/* Pilih Device */}
          <div className="col-md-6">
            <label htmlFor="true" className="form-label">
              Perangkat
            </label>
            <Select
              options={deviceList}
              placeholder="Pilih perangkat"
              onChange={e => setDevice(e.value)}
            />
          </div>

          {/* From Date */}
          <div className="col-md-6">
            <label htmlFor="true" className="form-label">
              Pilih tanggal{' '}
              <small style={{color: '#999'}}>
                <i>(mulai)</i>
              </small>{' '}
              <span style={{color: '#ff0000'}}>*</span>
            </label>
            <DatePicker
              // disabled={farm == null ? true : false}
              dateFormat="dd/MM/yyyy"
              placeholderText="Mulai tanggal..."
              className="form-control datepicker"
              selected={startDate}
              onChange={date => onChangeStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              maxDate={endDate}
            />
          </div>

          {/* End Date */}
          <div className="col-md-6">
            <label htmlFor="true" className="form-label">
              <small style={{color: '#999'}}>
                <i>(berakhir)</i>
              </small>{' '}
              <span style={{color: '#ff0000'}}>*</span>
            </label>
            <DatePicker
              // disabled={farm == null ? true : false}
              dateFormat="dd/MM/yyyy"
              placeholderText="Sampai tanggal..."
              className="form-control datepicker"
              selected={endDate}
              onChange={date => onChangeEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              maxDate={endDay}
            />
          </div>

          {/* Keterangan */}
          <span className="col-md-12">
            <span style={{color: '#ff0000'}}>*</span> Download data maksimal
            hanya 31 hari
          </span>

          {/* Button Process */}
          <div className={downloadBtn ? 'col-md-4' : 'col-md-12'}>
            <button
              type="button"
              className="btn btn-green w-100"
              onClick={() => handleDownload()}>
              <b>Proses</b>
            </button>
          </div>

          {/* Button Download */}
          {!downloadBtn ? (
            <></>
          ) : (
            <div className="col-md-4">
              <CSVLink
                data={csvData}
                filename={`${farm.label}_(${moment(startDate).format(
                  'DD-MM-YYYY'
                )})_(${moment(endDate).format('DD-MM-YYYY')}).csv`}
                className="btn btn-blue w-100">
                <b>Unduh Data (CSV)</b>
              </CSVLink>
            </div>
          )}

          {/* Button Download Average */}
          {!downloadBtn ? (
            <></>
          ) : (
            <div className="col-md-4">
              <CSVLink
                data={csvDataAvg.avg}
                filename={`${farm.label}_Average_(${moment(startDate).format(
                  'DD-MM-YYYY'
                )})_(${moment(endDate).format('DD-MM-YYYY')}).csv`}
                className="btn btn-red w-100">
                <b>Unduh Data Average(CSV)</b>
              </CSVLink>
            </div>
          )}

          {/* Button Download min */}
          {!downloadBtn ? (
            <></>
          ) : (
            <div className="col-md-4">
              <CSVLink
                data={csvDataAvg.min}
                filename={`${farm.label}_Min_(${moment(startDate).format(
                  'DD-MM-YYYY'
                )})_(${moment(endDate).format('DD-MM-YYYY')}).csv`}
                className="btn btn-yellow w-100">
                <b>Unduh Data (-)Min(CSV)</b>
              </CSVLink>
            </div>
          )}

          {/* Button Download Average */}
          {!downloadBtn ? (
            <></>
          ) : (
            <div className="col-md-4">
              <CSVLink
                data={csvDataAvg.max}
                filename={`${farm.label}_Max_(${moment(startDate).format(
                  'DD-MM-YYYY'
                )})_(${moment(endDate).format('DD-MM-YYYY')}).csv`}
                className="btn btn-purple w-100">
                <b>Unduh Data (+)Max(CSV)</b>
              </CSVLink>
            </div>
          )}
        </div>
      </Card>
    </div>
  );

  const downloadGrowthView = () => (
    <div className="col-md-9">
      <Card
        title={menu === 'download_growth' ? 'Download Data Pertumbuhan' : ''}>
        <div className="row row-decks row-cards">
          {/* Pilih Kebun */}
          <div className="col-md-6">
            <label htmlFor="true" className="form-label">
              Nama Kebun
            </label>
            <Select
              options={farmList}
              placeholder="Pilih kebun"
              onChange={e => {
                setDownloadBtn(false);
                fetchGrowthPlants(e);
                setDataGrowth({...dataGrowth, kebun: e.label});
              }}
            />
            {/* {errors.title && (
              <div className="pt-1" style={{ color: 'red' }}>
                Pilih nama kebun
              </div>
            )} */}
          </div>
          <div className="col-md-6">
            <label htmlFor="true" className="form-label">
              Pilih Tanaman
            </label>
            <Select
              options={plants}
              placeholder="Pilih tanaman"
              isDisabled={plants.length === 0 ? 1 : 0}
              onChange={e => {
                setDownloadBtn(false);
                fetchGrowthDownload(e);
                setDataGrowth({
                  ...dataGrowth,
                  tanaman: {
                    index: e.index,
                    nama: e.label,
                    parameter: e.parameter,
                  },
                });
              }}
            />
            {/* {errors.title && (
              <div className="pt-1" style={{ color: 'red' }}>
                Pilih nama kebun
              </div>
            )} */}
          </div>
          <div className="col-md-12">
            <label htmlFor="true" className="form-label">
              Pilih Tanggal
            </label>
            <Select
              options={growth}
              placeholder="Pilih tanggal"
              isDisabled={growth.length === 0 ? 1 : 0}
              onChange={e => {
                setDownloadBtn(false);
                setDataGrowth({
                  ...dataGrowth,
                  tanggal: e.label,
                  data: e.data,
                });
              }}
            />

            {/* {errors.title && (
              <div className="pt-1" style={{ color: 'red' }}>
                Pilih nama kebun
              </div>
            )} */}
          </div>

          {/* Button Process */}
          <div className={downloadBtn ? 'col-md-6' : 'col-md-12'}>
            <button
              type="button"
              className="btn btn-green w-100"
              onClick={() => handleDownloadGrowth()}>
              <b>Proses</b>
            </button>
          </div>

          {/* Button Download */}
          {downloadBtn && (
            <div className="col-md-6">
              <CSVLink
                data={csvData}
                filename={`${dataGrowth.kebun}_(${dataGrowth.tanggal})_(${dataGrowth.tanaman.nama}).csv`}
                className="btn btn-blue w-100">
                <b>Unduh Data (CSV)</b>
              </CSVLink>
            </div>
          )}
        </div>
      </Card>
    </div>
  );

  const downloadFarmView = () => (
    <div className="col-md-9">
      <Card title="Download Data Kebun">
        <div className="row row-decks row-cards">
          {/* Pilih Kebun */}
          <div className="col-md-12">
            <label htmlFor="true" className="form-label">
              Nama Kebun
            </label>
            <Select
              options={farmList}
              placeholder="Pilih kebun"
              isMulti
              closeMenuOnSelect={false}
              onChange={e => {
                setFarm(e);
              }}
            />
          </div>

          {/* Button Process */}
          <div className={downloadBtn ? 'col-md-6' : 'col-md-12'}>
            <button
              type="button"
              className="btn btn-green w-100"
              onClick={() => handleDownloadFarm()}>
              <b>Proses</b>
            </button>
          </div>

          {/* Button Download */}
          {downloadBtn && (
            <div className="col-md-6">
              <CSVLink
                data={csvData}
                filename={`${dataGrowth.kebun}_(${dataGrowth.tanggal})_(${dataGrowth.tanaman.nama}).csv`}
                className="btn btn-blue w-100">
                <b>Unduh Data (CSV)</b>
              </CSVLink>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
  const downloadOrderView = () => (
    <div className="col-md-9">
      <Card title="Download Data Penawaran">
        <div className="row row-decks row-cards">
          {/* Pilih Kebun */}
          <div className="col-md-12">
            <label htmlFor="true" className="form-label">
              Nama Pemesan
            </label>
            <Select
              options={orderList.map(e => ({
                value: e.id,
                label: `${e.name} (${moment(e.created_at).format(
                  'DD-MM-YYYY'
                )})`,
              }))}
              placeholder="Pilih Pemesan"
              isMulti
              closeMenuOnSelect={false}
              onChange={e => {
                setOrder(e);
                setDownloadBtn(false);
              }}
            />
          </div>

          {/* Button Process */}
          <div className={downloadBtn ? 'col-md-6' : 'col-md-12'}>
            <button
              type="button"
              className="btn btn-green w-100"
              onClick={() => handleDownloadOrder()}>
              <b>Proses</b>
            </button>
          </div>

          {/* Button Download */}
          {downloadBtn && (
            <div className="col-md-6">
              <CSVLink
                data={csvData}
                filename="File Data Penawaran.csv"
                className="btn btn-blue w-100">
                <b>Unduh Data (CSV)</b>
              </CSVLink>
            </div>
          )}
        </div>
      </Card>
    </div>
  );

  const handleView = () => {
    let views;

    if (isLoading) {
      views = <LoadingView />;
      // } else if (menu === 'analytic_data') {
      //   views = analyticDataView();
    } else if (menu === 'download_data') {
      views = downloadDataView();
    } else if (menu === 'download_growth') {
      views = downloadGrowthView();
    } else if (menu === 'download_farm') {
      views = downloadFarmView();
    } else if (menu === 'download_order') {
      views = downloadOrderView();
    } else {
      views = <></>;
    }

    return views;
  };

  return (
    <div className="container-xl">
      {/* header */}
      {header()}

      <div className="row">
        {sidebar()}

        {/* content */}
        {handleView()}
      </div>

      <Modal
        isOpen={modalIsOpen}
        // onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        // className="ModalReport"
        overlayClassName="OverlayReport"
        contentLabel="Example Modal">
        <div className="container h-100">
          <div className="d-flex align-items-center flex-column justify-content-center h-100">
            <img
              className="img"
              style={{marginBottom: 20, marginTop: 10}}
              src={`${process.env.PUBLIC_URL}/dist/img/preloader.gif`}
              alt="Logo"
            />
            <p>Sedang diproses, tunggu beberapa saat...</p>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Report;
