import create from 'zustand';
import {newAPI} from '../services/baseAPI';

const useUserStore = create(set => ({
  // State
  user: undefined,
  loading: true,
  token: null,
  validationReport: null,

  // Action
  setUser: user => set({user}),
  setLoading: loading => set({loading}),
  setToken: token => set({token}),
  setValidationReport: validationReport => set({validationReport}),

  login: async (email, password) => {
    try {
      const {data} = await newAPI.post('/v3/login', {
        email,
        password,
      });
      localStorage.setItem('token', JSON.stringify(data.data.token));
      localStorage.setItem('user', JSON.stringify(data.data.user));
      set({
        token: data.data.token,
        user: data.data.user,
        validationReport: null,
      });
    } catch (error) {
      set({
        validationReport: error,
      });
      console.log(error);
    }
  },

  logout: token => {
    localStorage.clear();
    set({token});
  },

  refreshSession: () => {
    const tokenSession = localStorage.getItem('token');
    const userSession = localStorage.getItem('user');

    if (tokenSession) {
      set({
        token: JSON.parse(tokenSession),
        user: JSON.parse(userSession),
        loading: false,
      });
    } else {
      set({
        token: null,
        user: undefined,
        loading: false,
      });
    }
  },
}));

export default useUserStore;
