/* eslint-disable import/prefer-default-export */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
import React from 'react';
import './Modal.css';

const ModalBottom = ({isModalOpen = false, setIsModalOpen, children}) => (
  <>
    <div className={`Modal ${isModalOpen ? 'Show' : ''}`}>
      <div className="row row-decks row-cards my-auto py-3">
        <h3 className="col-md-11 my-auto">Tugas</h3>
        <input
          className="col-md-1 my-auto btn btn-danger"
          type="button"
          value="Tutup"
          onClick={() => setIsModalOpen(!isModalOpen)}
        />
      </div>
      {children}
    </div>
    <div
      className={`Overlay ${isModalOpen ? 'Show' : ''}`}
      onClick={() => setIsModalOpen(!isModalOpen)}
    />
  </>
);

export {ModalBottom};
