import React, {useState, useEffect} from 'react';
import idx from 'idx';
import moment from 'moment';
import PropTypes from 'prop-types';
import {newAPI, oldAPI} from '../../../services/baseAPI';
import Icon from '../../../components/Icon';
import Card from '../../../components/SimpleCard';
import LoadingView from '../../../components/Loading';

function Current(props) {
  const [isLoading, setLoading] = useState(false);
  const [climate, setClimate] = useState(null);
  const [station, setStation] = useState(null);
  const [dose, setDose] = useState(null);

  const fetchCurrentSensor = async () => {
    setLoading(true);

    await newAPI
      .post('/v3/farms/current_sensor', {
        farmCode: props.farmCode,
      })
      .then(res => {
        const climateTmp = [];
        const getClimate = res.data.filter(item => item.jenis_data === 'zona');
        const getStation = res.data.find(item => item.jenis_data === 'aws');
        const getDose = res.data.find(item => item.jenis_data === 'dose');

        // collecting ground sensor per zone
        getClimate.forEach(item => {
          const ground = JSON.parse(item.data);
          climateTmp.push({
            datetime: item.datetime,
            mois: idx(ground, _ => _.sensor.mois)
              ? parseFloat(ground.sensor.mois).toFixed(2)
              : 0,
            nutr: idx(ground, _ => _.sensor.nutr)
              ? parseFloat(ground.sensor.nutr).toFixed(2)
              : 0,
            soilt: idx(ground, _ => _.sensor.soilt)
              ? parseFloat(ground.sensor.soilt).toFixed(2)
              : 0,
            light: idx(ground, _ => _.sensor.light)
              ? parseFloat(ground.sensor.light).toFixed(2)
              : 0,
            soilph: idx(ground, _ => _.sensor.soilph)
              ? parseFloat(ground.sensor.soilph).toFixed(2)
              : 0,
            temp: idx(ground, _ => _.sensor.temp)
              ? parseFloat(ground.sensor.temp).toFixed(2)
              : 0,
            hum: idx(ground, _ => _.sensor.hum)
              ? parseFloat(ground.sensor.hum).toFixed(2)
              : 0,
            pressure: idx(ground, _ => _.sensor.pressure)
              ? parseFloat(ground.sensor.pressure).toFixed(2)
              : 0,
            batre: idx(ground, _ => _.sensor.batre)
              ? parseFloat(ground.sensor.batre).toFixed(2)
              : 0,
            N: idx(ground, _ => _.sensor.N)
              ? parseFloat(ground.sensor.N).toFixed(2)
              : 0,
            P: idx(ground, _ => _.sensor.P)
              ? parseFloat(ground.sensor.P).toFixed(2)
              : 0,
            K: idx(ground, _ => _.sensor.K)
              ? parseFloat(ground.sensor.K).toFixed(2)
              : 0,
          });
        });
        setClimate(climateTmp);

        // collecting station sensor
        if (getStation) {
          const parseStation = JSON.parse(getStation.data);
          setStation({
            datetime: getStation.datetime,
            windDirection: idx(parseStation, _ => _.windDirection)
              ? parseFloat(parseStation.windDirection).toFixed(2)
              : 0,
            windSpeedAverage: idx(parseStation, _ => _.windSpeedAverage)
              ? parseFloat(parseStation.windSpeedAverage).toFixed(2)
              : 0,
            windSpeedMax: idx(parseStation, _ => _.windSpeedMax)
              ? parseFloat(parseStation.windSpeedMax).toFixed(2)
              : 0,
            rainFallOneHour: idx(parseStation, _ => _.rainFallOneHour)
              ? parseFloat(parseStation.rainFallOneHour).toFixed(2)
              : 0,
            rainFallOneDay: idx(parseStation, _ => _.rainFallOneDay)
              ? parseFloat(parseStation.rainFallOneDay).toFixed(2)
              : 0,
            temperature: idx(parseStation, _ => _.temperature)
              ? parseFloat(parseStation.temperature).toFixed(2)
              : 0,
            humidity: idx(parseStation, _ => _.humidity)
              ? parseFloat(parseStation.humidity).toFixed(2)
              : 0,
            pressure: idx(parseStation, _ => _.pressure)
              ? parseFloat(parseStation.pressure).toFixed(2)
              : 0,
            voltBatt: idx(parseStation, _ => _.voltBatt)
              ? parseFloat(parseStation.voltBatt).toFixed(2)
              : 0,
            currBatt: idx(parseStation, _ => _.currBatt)
              ? parseFloat(parseStation.currBatt).toFixed(2)
              : 0,
            voltSolar: idx(parseStation, _ => _.voltSolar)
              ? parseFloat(parseStation.voltSolar).toFixed(2)
              : 0,
            currSolar: idx(parseStation, _ => _.currSolar)
              ? parseFloat(parseStation.currSolar).toFixed(2)
              : 0,
            solar: idx(parseStation, _ => _.solar)
              ? parseFloat(parseStation.solar).toFixed(2)
              : 0,
            uv: idx(parseStation, _ => _.uv)
              ? parseFloat(parseStation.uv).toFixed(2)
              : 0,
            light: idx(parseStation, _ => _.light)
              ? parseFloat(parseStation.light).toFixed(2)
              : 0,
          });
        }

        // collecting dose sensor
        if (getDose) {
          const parseDose = JSON.parse(getDose.data);
          setDose({
            datetime: getDose.datetime,
            infoMode: idx(parseDose, _ => _.info.auto_mode)
              ? parseDose.info.auto_mode
              : false,
            infoCurrSeq: idx(parseDose, _ => _.info.current_seq)
              ? parseDose.info.current_seq
              : 0,
            infoValve: idx(parseDose, _ => _.info.fert_valve)
              ? parseDose.info.fert_valve
              : false,
            infoRadar: idx(parseDose, _ => _.info.sts_radar)
              ? parseDose.info.sts_radar
              : 'XXXX',
            sensorTDS: idx(parseDose, _ => _.sensor.tds)
              ? parseDose.sensor.tds
              : 0,
            sensorPH: idx(parseDose, _ => _.sensor.ph)
              ? parseDose.sensor.ph
              : 0,
            sensorTemp: idx(parseDose, _ => _.sensor.temp)
              ? parseDose.sensor.temp
              : 0,
          });
        }
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchCurrentSensor();
  }, []);

  const checkStatusDevice = dateTime => {
    const refDate = new Date(dateTime);
    const nowDate = new Date();
    const res = parseInt((nowDate.getTime() - refDate) / 1000, 10);
    return res;
  };

  const checkStatusDose = params => {
    let mixCurrProcess = '';
    if (params === 0) {
      mixCurrProcess = 'Peracikan pupuk tidak aktif';
    } else if (params === 1) {
      mixCurrProcess = 'Sedang Mengisi Air Bersih, Valve 1 & 6 menyala';
    } else if (params === 2) {
      mixCurrProcess = 'Sedang Idle 5 detik, Valve 6 menyala';
    } else if (params === 3) {
      mixCurrProcess = 'Sedang Mengisi Pupuk A, Valve 1 & 3 menyala';
    } else if (params === 4) {
      mixCurrProcess = 'Sedang Flushing Pupuk A, Valve 1 & 6 menyala';
    } else if (params === 5) {
      mixCurrProcess =
        'Sedang Mengaduk Pupuk di toren AB Mix, Valve 1 & 2 menyala';
    } else if (params === 6) {
      mixCurrProcess = 'Sedang Mengisi Pupuk B, Valve 1 & 4 menyala';
    } else if (params === 7) {
      mixCurrProcess = 'Sedang Flushing Pupuk B, Valve 1 & 6 menyala';
    } else if (params === 8) {
      mixCurrProcess = 'Sedang Mengisi Pupuk POC, Valve 1 & 5 menyala';
    } else if (params === 9) {
      mixCurrProcess = 'Sedang Flushing Pupuk POC, Valve 1 & 6 menyala';
    } else {
      mixCurrProcess =
        'Peracikan Habibi Dose di Pause karena salah satu toren pupuk (A/B/POC) habis';
    }

    return mixCurrProcess;
  };

  const climateView = params => (
    <>
      {/*  HABIBI CLIMATE */}
      <div className="col-md-12">
        <div className="card-body py-0 border-bottom">
          <h3 className="d-flex" style={{marginBottom: 13, marginTop: 13}}>
            Sensor Lingkungan & Tanah (Climate)
          </h3>
        </div>
      </div>

      {/* status zona */}
      <div className="col-12 col-md-8">
        <Card className="text-center" styleBody={{padding: 0}}>
          <table className="table table-responsive">
            <thead>
              <tr>
                <th>Zona</th>
                <th>Update Terakhir</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody className="align-middle">
              {climate.map((item, index) => (
                <tr key={JSON.stringify(index)}>
                  <td>{index + 1}</td>
                  <td>{moment(item.datetime).format('DD/MM/YYYY HH:mm:ss')}</td>
                  <td>
                    <span
                      className={`badge ${
                        checkStatusDevice(item.datetime) >= 300
                          ? 'bg-gray'
                          : 'bg-green'
                      }`}>
                      {checkStatusDevice(item.datetime) >= 300
                        ? 'Offline'
                        : 'Online'}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
      </div>

      {/* NPK */}
      <div className="col-12 col-md-4">
        <Card className="text-center mx-auto" styleBody={{padding: 0}}>
          <table className="table table-responsive">
            <thead>
              <tr>
                <th>Zona</th>
                <th>N</th>
                <th>P</th>
                <th>K</th>
              </tr>
            </thead>
            <tbody className="align-middle">
              {climate.map((item, index) => (
                <tr key={JSON.stringify(index)}>
                  <td>{index + 1}</td>
                  <td>{item.N}</td>
                  <td>{item.P}</td>
                  <td>{item.K}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
      </div>

      {/* All Sensor */}
      <div className="col-12">
        <Card className="text-center mx-auto" styleBody={{padding: 0}}>
          <table className="table table-responsive">
            <thead>
              <tr>
                <th>_</th>
                <th colSpan={3}>Sensor Lingkungan</th>
                <th colSpan={4}>Sensor Tanah</th>
              </tr>
              <tr>
                <th>Zona</th>
                <th>Suhu</th>
                <th>Kelembaban</th>
                <th>Tekanan Udara</th>
                <th>Suhu</th>
                <th>Kadar Air</th>
                <th>Nutrisi</th>
                <th>Cahaya</th>
              </tr>
            </thead>
            <tbody className="align-middle">
              {climate.map((item, index) => (
                <tr key={JSON.stringify(index)}>
                  <td>{index + 1}</td>
                  {/* <td>{item.temp} °C<br/><div className="mx-auto bg-secondary rounded-pill w-3 h-1" /></td> */}
                  <td>{item.temp} °C</td>
                  <td>{item.hum} %</td>
                  <td>{item.pressure} hPa</td>
                  <td>{item.soilt} °C</td>
                  <td>{item.mois} %</td>
                  <td>{item.nutr} ppm</td>
                  <td>{item.light} Lux</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
      </div>
    </>
  );

  const stationView = params => (
    <>
      {/* HABIBI STATION (AWS) */}
      <div className="col-md-12">
        <div className="card-body py-0 border-bottom">
          <h3 className="d-flex" style={{marginBottom: 13, marginTop: 13}}>
            Habibi Station (AWS){' '}
            <span
              className={`ms-auto badge ${
                checkStatusDevice(station.datetime) >= 300
                  ? 'bg-gray'
                  : 'bg-green'
              }`}>
              {checkStatusDevice(station.datetime) >= 300
                ? 'Offline'
                : 'Online'}
            </span>
          </h3>
        </div>
      </div>

      {/* datetime */}
      <div className="col-6 col-md-3 ">
        <Card className="text-center mx-auto border-primary align-middle">
          <div>{moment(station.datetime).format('DD/MM/YYYY')}</div>
          <h1>{moment(station.datetime).format('HH:mm:ss')}</h1>
          <div>Update Terakhir</div>
          {/* <div className="badge bg-green mt-1"></div> */}
        </Card>
      </div>

      {/* Suhu */}
      <div className="col-6 col-md-3">
        <Card className="text-center" styleBody={{height: '100%'}}>
          <h1>{station.temperature}°C</h1>
          <div>Suhu</div>
          {/* <div className="badge bg-green">Baik</div> */}
        </Card>
      </div>

      {/* Kelembaban */}
      <div className="col-6 col-md-3">
        <Card className="text-center mx-auto">
          <h1>{station.humidity}%</h1>
          <div>Kelembaban</div>
          {/* <div className="badge bg-yellow">Berlebih</div> */}
        </Card>
      </div>

      {/* Cahaya */}
      <div className="col-6 col-md-3">
        <Card className="text-center mx-auto">
          <h1>{station.light} Lux</h1>
          <div>Cahaya</div>
          {/* <div className="badge">Redup</div> */}
        </Card>
      </div>

      {/* Tekanan Udara */}
      <div className="col-6 col-md-3">
        <Card className="text-center mx-auto">
          <h1>{station.pressure} hPa</h1>
          <div>Tekanan Udara</div>
          {/* <div className="badge bg-green">8834.29 Mdpl</div> */}
        </Card>
      </div>

      {/* Curah Hujan */}
      <div className="col-6 col-md-3">
        <Card className="text-center mx-auto">
          <div>Curah Hujan</div>
          <div className="badge bg-blue mt-2">
            {station.rainFallOneHour} mm/jam
          </div>
          <br />
          <div className="badge bg-blue mt-1">
            {station.rainFallOneDay}mm/hari
          </div>
        </Card>
      </div>

      {/* Arah Angin */}
      <div className="col-6 col-md-3">
        <Card className="text-center mx-auto">
          <h1>{station.windDirection}°</h1>
          <div>Arah Angin</div>
          {/* <div className="badge bg-green">Timur</div> */}
        </Card>
      </div>

      {/* Kecepatan Angin */}
      <div className="col-6 col-md-3">
        <Card className="text-center mx-auto">
          <div>Kecepatan Angin</div>
          <div className="badge bg-blue mt-2">
            Rata-Rata: {station.windSpeedAverage} m/s
          </div>
          <br />
          <div className="badge bg-blue mt-1">
            Maksimal: {station.windSpeedMax} m/s
          </div>
        </Card>
      </div>

      {/* uv */}
      <div className="col-6 col-md-3">
        <Card className="text-center mx-auto">
          <h1>{station.uv} Lux</h1>
          <div>UV</div>
          {/* <div className="badge">Maksimal</div> */}
        </Card>
      </div>

      {/* solar */}
      <div className="col-6 col-md-3">
        <Card className="text-center mx-auto">
          <h1>{station.solar}</h1>
          <div>Solar</div>
          {/* <div className="badge">Maksimal</div> */}
        </Card>
      </div>

      {/* volt solar */}
      <div className="col-6 col-md-3">
        <Card className="text-center mx-auto">
          <h1>{station.voltSolar}</h1>
          <div>Tegangan Solar</div>
          {/* <div className="badge">Maksimal</div> */}
        </Card>
      </div>

      {/* current solar */}
      <div className="col-6 col-md-3">
        <Card className="text-center mx-auto">
          <h1>{station.currSolar}</h1>
          <div>Arus Solar</div>
          {/* <div className="badge">Maksimal</div> */}
        </Card>
      </div>

      {/* volt batt */}
      <div className="col-6 col-md-3">
        <Card className="text-center mx-auto">
          <h1>{station.voltBatt}</h1>
          <div>Tegangan Baterai</div>
          {/* <div className="badge">Maksimal</div> */}
        </Card>
      </div>

      {/* current batt */}
      <div className="col-6 col-md-3">
        <Card className="text-center mx-auto">
          <h1>{station.currBatt}</h1>
          <div>Arus Baterai</div>
          {/* <div className="badge">Maksimal</div> */}
        </Card>
      </div>
    </>
  );

  const doseView = params => (
    <>
      {/* HABIBI DOSE */}
      <div className="col-md-12">
        <div className="card-body py-0 border-bottom">
          <h3 className="d-flex" style={{marginBottom: 13, marginTop: 13}}>
            Habibi Dose{' '}
            <span
              className={`ms-auto badge ${
                checkStatusDevice(dose.datetime) >= 300 ? 'bg-gray' : 'bg-green'
              }`}>
              {checkStatusDevice(dose.datetime) >= 300 ? 'Offline' : 'Online'}
            </span>
          </h3>
        </div>
      </div>

      {/* Pemupukan */}
      <div className="col-md-12">
        <Card title="Monitoring Pemupukan">
          <div className="d-flex row h-100 justify-content-center align-items-center">
            <div className="col-md-3 text-center">
              <Icon name="Droplet" size={35} />
              <p className="mt-2">Mode: {dose.infoValve ? 'Pupuk' : 'Air'}</p>
            </div>
            <div className="col-md-6">
              <div className="text-muted">Proses saat ini:</div>
              <div className="fw-bold">{checkStatusDose(dose.infoCurrSeq)}</div>
              <div className="text-muted mt-2">Mode Racik:</div>
              <div className="fw-bold">
                {dose.infoMode ? 'Otomatis' : 'Manual'}
              </div>
            </div>
            <div className="col-md-3 text-end">
              <div>{moment(dose.datetime).format('DD/MM/YYYY')}</div>
              <h1>{moment(dose.datetime).format('HH:mm:ss')}</h1>
              <div>Update Terakhir</div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );

  const currentView = params => (
    <>
      {/*  Current */}
      <div className="col-12">
        <Card className="text-center mx-auto">
          <div>
            Tampilan data terbaru dari seluruh sensor yang terpasang di lahan
            pertanian
          </div>
        </Card>
      </div>

      {climate ? climateView() : <></>}

      {station ? stationView() : <></>}

      {dose ? doseView() : <></>}
    </>
  );

  return (
    <>
      {/* content */}
      {isLoading ? <LoadingView /> : currentView()}
    </>
  );
}

Current.propTypes = {
  farmCode: PropTypes.string.isRequired, // arrayOf
};

export default Current;
