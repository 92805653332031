import React, {useState, useEffect, useCallback} from 'react';
import {useParams, useHistory, Link, Redirect} from 'react-router-dom';
import moment from 'moment';

import {maplesAPI, newAPI, newURI, oldAPI} from '../../services/baseAPI';
import Card from '../../components/SimpleCard';
import Icon from '../../components/Icon';
import LoadingView from '../../components/Loading';
import useUserStore from '../../stores/Users';

function AgricultureShow() {
  // State & Fetch
  const {menu, id, index} = useParams();
  const history = useHistory();
  const token = useUserStore(state => state.token);

  const [data, setData] = useState([]);
  const [plantData, setPlantData] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // growth
  const [dataParameter, setDataParameter] = useState([]);
  const [dataDate, setDataDate] = useState([]);

  const fetchDisease = async () => {
    setIsLoading(true);

    try {
      const res = await oldAPI.get(`/v2/pestdisease/${id}`, {
        headers: {Authorization: `Bearer ${token}`},
      });
      setData(res.data.data.pest_disease);
      setPlantData(res.data.data.plant);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchArticle = async () => {
    setIsLoading(true);
    await newAPI
      .get(`/articles/${id}`)
      .then(res => {
        setData(res.data[0]);
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => setIsLoading(false));
  };

  const fetchGrowth = async () => {
    setIsLoading(true);
    await newAPI
      .get(`/growth/${id}/show`)
      .then(res => {
        const growth = res.data;
        setData(growth[0][0]);
        setDataParameter(growth[1]);
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchDataGrowth = async () => {
    setIsLoading(true);
    await newAPI
      .get(`/growth/${id}/show/${index}`)
      .then(res => {
        if (res.data === undefined) {
          setDataDate([]);
        } else {
          setDataDate(JSON.parse(res.data.parameters_data));
        }
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => {});
  };

  const fetchDeleteDataGrowth = async param => {
    setIsLoading(true);
    await newAPI
      .delete(`/growth/${id}/show/${index}/${param}`)
      .then(res => {
        // alert('berhasil');
        if (res.data === 1) {
          setDataDate([]);
        }
      })
      .catch(error => console.log(`error ${error}`))
      .finally(() => {
        fetchDataGrowth();
        setIsLoading(false);
      });
  };

  const fetchFertilizerDetail = async () => {
    try {
      setIsLoading(true);
      const fields = ['name', 'cost', 'content'];

      const response = await maplesAPI.get(`/api/v1/fertilizer/${id}`, {
        headers: {token: `${token}`},
      });

      const fertilizer = response.data.data;

      setData(fertilizer);
    } catch (error) {
      console.error(`error ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPlantDetail = async () => {
    try {
      setIsLoading(true);
      const response = await newAPI.get(`/plants/${id}`, {
        headers: {Authorization: `Bearer ${token}`},
      });

      const plant = response.data[0];
      console.log(plant);
      setData(plant);
      setImagePreview(`${newURI}/plants/${plant.image}`);
    } catch (error) {
      console.error(`error ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    switch (menu) {
      case 'article':
        fetchArticle();
        break;
      case 'disease':
        fetchDisease();
        break;
      case 'growth':
        if (index === undefined) {
          fetchGrowth();
        } else {
          fetchGrowth();
          fetchDataGrowth();
        }
        break;
      case 'plant':
        fetchPlantDetail();
        break;
      case 'fertilizer':
        fetchFertilizerDetail();
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (index) {
      fetchDataGrowth();
    }
  }, [index]);

  // View
  const headerView = () => {
    let name = '';
    if (menu === 'article') name = 'Artikel';
    if (menu === 'disease') name = 'Hama Penyakit';
    if (menu === 'growth') name = 'Data Pertumbuhan';
    if (menu === 'plant') name = 'Tanaman';
    if (menu === 'cultivation') name = 'Data Budidaya';
    if (menu === 'fertilizer') name = 'Pupuk';

    return (
      <>
        <div className="page-header d-print-none">
          <div className="row align-items-center">
            <button
              aria-label="Back Button"
              type="button"
              onClick={() => history.push(`/agriculture/${menu}`)}
              className="col-2"
              style={{
                marginLeft: '8px',
                marginRight: '10px',
                height: 40,
                width: 40,
                borderRadius: 20,
                backgroundColor: 'rgba(255, 0, 0, 0)',
                borderWidth: 0,
              }}>
              <Icon name="ArrowLeft" color="#333" size={20} />
            </button>
            <div className="col">
              <div className="page-pretitle">Lihat</div>
              <h2 className="page-title">{name}</h2>
            </div>
          </div>
        </div>
      </>
    );
  };

  const articleView = () => (
    <Card className="d-flex align-items-center py-1">
      <>
        <h1 className="text-center">{data.title}</h1>
        <p className="text-center text-capitalize">
          {data.category} | {data.created_at}
        </p>
        <div className="text-center">
          <img
            src={`${newURI}/articles/${data.image}`}
            alt=""
            height={200}
            width={300}
            className="mb-3"
          />
        </div>
        <div dangerouslySetInnerHTML={{__html: data.content}} />
      </>
    </Card>
  );

  const diseaseView = () => (
    <Card className="d-flex align-items-center py-1">
      <>
        <h1 className="text-center">{data.name}</h1>

        <div className="text-center">
          <img
            src={`https://habibigarden.com/dashboard/public/dist/img/hamapenyakit/${data.img}`}
            alt=""
            height={200}
            width={300}
            className="mb-3"
          />
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b>Tanaman :</b>
            </p>
            <ul>
              {plantData.map(plant => (
                <li key={plant.id}>{plant.plants_name}</li>
              ))}
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b>Tanaman Inang:</b>
            </p>
            <ul>
              {plantData.map(plant => (
                <li key={plant.id} style={{margin: '2px'}}>
                  {plant.plants_name}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b>Nama Ilmiah:</b>
              {data.scientific}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b>Dekskripsi:</b>
              <div dangerouslySetInnerHTML={{__html: data.desc}} />
            </p>
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b>Saran Perlakuan:</b>
              <div dangerouslySetInnerHTML={{__html: data.treatment}} />
            </p>
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b> Jenis HPT : </b>
              {data.hpt === 1 && 'Hama'}
              {data.hpt === 2 && 'Virus'}
              {data.hpt === 3 && 'Jamur'}
              {data.hpt === 4 && 'Bakteri'}
              {data.hpt === 5 && 'Tular Tanah'}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b>Gejala :</b>
              <div dangerouslySetInnerHTML={{__html: data.symptoms}} />
            </p>
          </div>
          <hr />
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b> Jenis Pestisida :</b>
              {data.type_pesticide}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b> Bahan Aktif :</b>
              <div dangerouslySetInnerHTML={{__html: data.active_ingredient}} />
            </p>
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b>Luas Area :</b>
              {data.area}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b> Satuan : :</b>
              {data.unit}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <p>
              <b> 0 (Kg/L) : :</b>
              {data.dvs_0}
            </p>
          </div>
          <div className="col-md-3">
            <p>
              <b> 15 (g/L) : :</b>
              {data.dvs_15}
            </p>
          </div>
          <div className="col-md-3">
            <p>
              <b> 20 (g/L) : :</b>
              {data.dvs_20}
            </p>
          </div>
          <div className="col-md-3">
            <p>
              <b> 25 (g/L) : :</b>
              {data.dvs_25}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b>Dosis rata-rata :</b>
              {data.average_dosis}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b> Petunjuk Penggunaan :</b>
              <div dangerouslySetInnerHTML={{__html: data.use_instruction}} />
            </p>
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b> Tingkat Berbahaya : </b>
              {data.hpt === 1 && 'Cukup Berbahaya'}
              {data.hpt === 2 && 'Sangat Berbahaya Sekali'}
              {data.hpt === 3 && 'Berbahaya Sekali'}
              {data.hpt === 4 && 'Berbahaya'}
              {data.hpt === 5 && 'Tidak Berbahaya Pada Pemakaian Normal'}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p>
              <b> Tindak Pencegahan :</b>
              <div dangerouslySetInnerHTML={{__html: data.precaution}} />
            </p>
            <hr />
          </div>
        </div>
      </>
    </Card>
  );

  const growthView = () => {
    const array = [];
    const parameter = dataParameter;
    const arrayColumn = (arr, n) => arr.map(x => x[n]);
    parameter.forEach(element => {
      const arrayTemp = JSON.parse(element.parameters_data);
      const arrayTmp = [];
      for (let i = 0; i < arrayTemp[0].length; i += 1) {
        const arraySum = arrayColumn(arrayTemp, i).reduce(
          (a, b) => parseFloat(a) + parseFloat(b),
          0
        );
        arrayTmp.push(parseFloat((arraySum / arrayTemp.length).toFixed(2)));
      }
      array.push(arrayTmp);
    });
    return (
      <Card className="d-flex  py-1">
        <>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-2">
                  <p>Sayuran / Buah</p>
                </div>
                <div className="col-md-10">
                  <p>: {data.plant_name}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <p>Kebun</p>
                </div>
                <div className="col-md-10">
                  <p>: {data.farm_name}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <p>HST</p>
                </div>
                <div className="col-md-10">
                  <p>: {data.hst}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <p>Tangal Tanam</p>
                </div>
                <div className="col-md-10">
                  <p>: {moment(data.planted).format('DD/MM/Y')}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <p>Tanngal Panen</p>
                </div>
                <div className="col-md-10">
                  <p>: {moment(data.harvest).format('DD/MM/Y')}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <p>Catatan</p>
                </div>
                <div className="col-md-10">
                  <p>:</p>
                  <div dangerouslySetInnerHTML={{__html: data.note}} />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-12">
              <table className="table table-vcenter card-table table-striped">
                <thead>
                  <tr>
                    <td rowSpan="2">No</td>
                    <td rowSpan="2">Parameter</td>
                    <td className="text-center" colSpan={dataParameter.length}>
                      Tanggal
                    </td>
                    <td className="text-center" rowSpan="2">
                      Grafik
                    </td>
                  </tr>
                  <tr>
                    {dataParameter.map(x => (
                      <td className="text-center">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        {/* <a className="btn btn-blue w-50" href="#">
                          {moment(x.created_at).format('DD/MM/Y')}
                        </a> */}
                        <Link
                          className="btn btn-blue w-50"
                          to={`/agriculture/${menu}/${id}/show/${moment(
                            x.created_at
                          ).format('Y-MM-D')}`}>
                          {moment(x.created_at).format('DD/MM/Y')}
                        </Link>
                      </td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.parameters
                    ? JSON.parse(data.parameters).map((x, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{x}</td>
                          {array.map(param => (
                            <td className="text-center">{param[i]}</td>
                          ))}
                        </tr>
                      ))
                    : ''}
                </tbody>
              </table>
            </div>
          </div>
        </>
      </Card>
    );
  };

  const growthDataView = () => (
    <Card className="d-flex  py-1">
      <>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-2">
                <p>Sayuran / Buah</p>
              </div>
              <div className="col-md-10">
                <p>: {data.plant_name}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <p>Kebun</p>
              </div>
              <div className="col-md-10">
                <p>: {data.farm_name}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <p>HST</p>
              </div>
              <div className="col-md-10">
                <p>: {data.hst}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <p>Tangal Tanam</p>
              </div>
              <div className="col-md-10">
                <p>: {moment(data.planted).format('DD/MM/Y')}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <p>Tanngal Panen</p>
              </div>
              <div className="col-md-10">
                <p>: {moment(data.harvest).format('DD/MM/Y')}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <p>Catatan</p>
              </div>
              <div className="col-md-10">
                <p>:</p>
                <div dangerouslySetInnerHTML={{__html: data.note}} />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <table className="table table-vcenter card-table table-striped">
              <thead>
                <tr>
                  <td rowSpan="2">No</td>
                  <td rowSpan="2">Parameter</td>
                  <td className="text-center" colSpan={dataDate.length}>
                    Nomor
                  </td>
                </tr>
                <tr>
                  {dataDate.map((x, i) => (
                    <td className="text-center">
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <button
                        type="button"
                        onClick={() => {
                          fetchDeleteDataGrowth(i);
                        }}
                        className="btn btn-blue w-10">
                        {i}
                      </button>
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.parameters
                  ? JSON.parse(data.parameters).map((x, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{x}</td>
                        {dataDate.map(param => (
                          <td className="text-center">{param[i]}</td>
                        ))}
                      </tr>
                    ))
                  : ''}
              </tbody>
            </table>
          </div>
        </div>
      </>
    </Card>
  );

  const betaView = () => (
    <>
      <Card className="d-flex align-items-center py-1">
        <p className="text-red">Under Construction</p>
      </Card>
    </>
  );

  const plantView = () => (
    <div>
      <Card title="Detail Tanaman">
        <div>
          <h1 className="text-center mb-3">{data.plants_name}</h1>
          <div className="row mb-1">
            <div className="col-md-6 text-center">
              <img
                src={imagePreview}
                alt={data.plants_name}
                height={200}
                width={300}
              />
            </div>
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="row">Scientific name</th>
                    <td>{data.scientific_name}</td>
                  </tr>
                  <tr>
                    <th scope="row">Family</th>
                    <td>{data.family}</td>
                  </tr>
                  <tr>
                    <th scope="row">Genus</th>
                    <td>{data.genus}</td>
                  </tr>
                  <tr>
                    <th scope="row">Origin</th>
                    <td>{data.country_origin}</td>
                  </tr>
                  <tr>
                    <th scope="row">Waktu panen</th>
                    <td>{data.harvest_est} hari</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <h2>Rekomendasi Pembudidayaan</h2>
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="row">Nutrisi N</th>
                    <td>{data.n}</td>
                  </tr>
                  <tr>
                    <th scope="row">Nutrisi P</th>
                    <td>{data.p}</td>
                  </tr>
                  <tr>
                    <th scope="row">Nutrisi K</th>
                    <td>{data.k}</td>
                  </tr>
                  <tr>
                    <th scope="row">pH Tanah</th>
                    <td>
                      {data.min_ph} - {data.max_ph}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Suhu Tanah</th>
                    <td>
                      {data.min_soil} - {data.max_soil} °C
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Suhu Udara</th>
                    <td>
                      {data.min_temperature} - {data.max_temperature} °C
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Kelembaban</th>
                    <td>
                      {data.min_humidity} - {data.max_humidity} %
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Cahaya</th>
                    <td>
                      {data.min_light} - {data.max_light} lux
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Kebutuhan Nutrisi</th>
                    <td>
                      {data.min_nutrient} - {data.max_nutrient}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Kelembaban Tanah</th>
                    <td>
                      {data.min_moisture} - {data.max_moisture} %
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-4">
              <h2>Informasi Pembudidayaan</h2>
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="row">Metode Penyemaian</th>
                    <td>{data.seeding}</td>
                  </tr>
                  <tr>
                    <th scope="row">Tempat Penyemaian</th>
                    <td>{data.seeding_place}</td>
                  </tr>
                  <tr>
                    <th scope="row">Bulan Penyemaian</th>
                    <td>{data.seeding_month}</td>
                  </tr>
                  <tr>
                    <th scope="row">Bulan Pembudidayaan</th>
                    <td>{data.cultivation_month}</td>
                  </tr>
                  <tr>
                    <th scope="row">Waktu Germinasi</th>
                    <td>
                      {Math.floor(data.min_germination)} -{' '}
                      {Math.floor(data.max_germination)} Hari
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-4">
              <h2>Teknik Pembudidayaan</h2>
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="row">Jarak Pembenihan</th>
                    <td>
                      {data.min_seed_distance} - {data.max_seed_distance} cm
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Jarak Tanam</th>
                    <td>
                      {data.min_planting_distance} -{' '}
                      {data.max_planting_distance} cm
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Jarak Antar Bedeng</th>
                    <td>
                      {data.min_planting_row_distance} -{' '}
                      {data.max_planting_row_distance} cm
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Kedalaman Penanaman</th>
                    <td>
                      {data.min_plant_depth} - {data.max_plant_depth} cm
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-8">
              <h2>Deskripsi</h2>
              <div dangerouslySetInnerHTML={{__html: data.description}} />
            </div>
            <div className="col-md-4">
              <div className="row">
                <h2>Note</h2>
                <div dangerouslySetInnerHTML={{__html: data.note}} />
              </div>
              <div className="row">
                <h2>Kebutuhan Air</h2>
                <p>{data.water_needs}</p>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );

  const fertilizerView = () => (
    <>
      {/* Form Tambah Pupuk */}
      <Card title="Detail Pupuk">
        <form>
          <div className="row row-decks row-cards">
            {/*  Fertilizer Name  */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Nama Pupuk
              </label>
              <input
                name="name"
                type="text"
                className="form-control"
                placeholder="Masukan nama pupuk"
                defaultValue={data.name}
                disabled
              />
            </div>

            {/*  Fertilizer Price  */}
            <div className="col-md-6">
              <label htmlFor="true" className="form-label">
                Harga Pupuk
              </label>
              <input
                name="cost"
                type="number"
                className="form-control"
                placeholder="Masukan harga pupuk per kg"
                defaultValue={data.cost}
                disabled
              />
            </div>

            {/*  Fertilizer Content  */}
            <div className="col-md-4">
              <label htmlFor="true" className="form-label">
                N
              </label>
              <input
                name="N"
                type="number"
                className="form-control"
                placeholder="Masukan nilai N"
                defaultValue={data.content.N}
                disabled
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="true" className="form-label">
                P
              </label>
              <input
                name="P"
                type="number"
                className="form-control"
                placeholder="Masukan nilai P"
                defaultValue={data.content.P}
                disabled
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="true" className="form-label">
                K
              </label>
              <input
                name="K"
                type="number"
                className="form-control"
                placeholder="Masukan nilai K"
                defaultValue={data.content.K}
                disabled
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="true" className="form-label">
                Ca
              </label>
              <input
                name="Ca"
                type="number"
                className="form-control"
                placeholder="Masukan nilai Ca"
                defaultValue={data.content.Ca}
                disabled
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="true" className="form-label">
                Mg
              </label>
              <input
                name="Mg"
                type="number"
                className="form-control"
                placeholder="Masukan nilai Mg"
                defaultValue={data.content.Mg}
                disabled
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="true" className="form-label">
                S
              </label>
              <input
                name="S"
                type="number"
                className="form-control"
                placeholder="Masukan nilai S"
                defaultValue={data.content.S}
                disabled
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="true" className="form-label">
                B
              </label>
              <input
                name="B"
                type="number"
                className="form-control"
                placeholder="Masukan nilai B"
                defaultValue={data.content.B}
                disabled
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="true" className="form-label">
                Cu
              </label>
              <input
                name="Cu"
                type="number"
                className="form-control"
                placeholder="Masukan nilai Cu"
                defaultValue={data.content.Cu}
                disabled
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="true" className="form-label">
                Fe
              </label>
              <input
                name="Fe"
                type="number"
                className="form-control"
                placeholder="Masukan nilai Fe"
                defaultValue={data.content.Fe}
                disabled
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="true" className="form-label">
                Mn
              </label>
              <input
                name="Mn"
                type="number"
                className="form-control"
                placeholder="Masukan nilai Mn"
                defaultValue={data.content.Mn}
                disabled
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="true" className="form-label">
                Mo
              </label>
              <input
                name="Mo"
                type="number"
                className="form-control"
                placeholder="Masukan nilai Mo"
                defaultValue={data.content.Mo}
                disabled
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="true" className="form-label">
                Zn
              </label>
              <input
                name="Zn"
                type="number"
                className="form-control"
                placeholder="Masukan nilai Zn"
                defaultValue={data.content.Zn}
                disabled
              />
            </div>

            <hr />
          </div>
        </form>
        <div className="col-md-12">
          <input
            className="btn btn-primary px-5 btn-block"
            value="Kembali"
            onClick={() => history.push('/agriculture/fertilizer')}
          />
        </div>
      </Card>
    </>
  );

  const handleView = () => {
    switch (menu) {
      case 'article':
        return articleView();
      case 'disease':
        return diseaseView();
      case 'growth':
        if (index) {
          return growthDataView();
        }
        return growthView();
      case 'plant':
        return plantView();
      case 'fertilizer':
        return fertilizerView();
      default:
        return betaView();
    }
  };

  return (
    <div className="container-xl">
      {headerView()}

      <div className="row">{isLoading ? <LoadingView /> : handleView()}</div>
    </div>
  );
}

export default AgricultureShow;
