import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import useUserStore from '../../stores/Users';

import Icon from '../../components/Icon';

function Login() {
  // state
  const {login, validationReport} = useUserStore(state => state);
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);

  // controller
  const handleLogin = e => {
    e.preventDefault();
    setLoading(true);
    const auth = login(phone, password);
    if (auth || !auth) {
      setLoading(false);
    }
  };

  const handleReport = report => {
    let res;
    if (report === null) {
      res = '';
    } else if (report.name === 'Error') {
      res =
        'No. telp atau password yang anda masukan tidak sesuai, silahkan coba lagi...';
    } else {
      res = '';
    }
    return res;
  };

  // view
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
      <div className="container-tight py-6">
        <form
          className="card card-md"
          action="."
          method="get"
          autoComplete="off">
          <div className="card-body">
            <div className="text-center mb-4">
              <Link to="/farm">
                <img
                  src={`${process.env.PUBLIC_URL}./static/hage.svg`}
                  height="45"
                  alt="logo"
                />
              </Link>
            </div>

            {/* Email */}
            <div className="mb-3">
              <label htmlFor="true" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter email"
                onChange={e => setPhone(e.target.value)}
              />
            </div>

            {/* Password */}
            <div className="mb-2">
              <label htmlFor="true" className="form-label">
                Password
                {/* <span className='form-label-description'>
                  <a href='#forgot'>Lupa password</a>
                </span> */}
              </label>
              <div className="input-group input-group-flat">
                <input
                  type={showPass ? 'text' : 'password'}
                  hidden={false}
                  className="form-control"
                  placeholder="Password"
                  autoComplete="off"
                  onChange={e => setPassword(e.target.value)}
                />
                <span
                  className="btn input-group-text"
                  onClick={() => setShowPass(!showPass)}
                  aria-hidden="true">
                  <Icon
                    name={showPass ? 'EyeOff' : 'Eye'}
                    size={16}
                    style={{marginLeft: 15}}
                  />
                </span>
              </div>
            </div>

            {/* Password */}
            <div className="mb-2">
              <label htmlFor="true" className="form-label text-red">
                {handleReport(validationReport)}
              </label>
            </div>

            {/* <div className='mb-2'>
              <label className='form-check'>
                <input type='checkbox' className='form-check-input' />
                <span className='form-check-label'>Remember me on this device</span>
              </label>
            </div> */}

            <div className="form-footer">
              <button
                type="submit"
                className="btn btn-primary w-100"
                onClick={e => handleLogin(e)}>
                Masuk
              </button>
            </div>
          </div>
        </form>
        {/* <div className="text-center text-muted mt-3">
          Belum punya akun? <Link to="/register">Daftar</Link>
        </div> */}
      </div>
    </div>
  );
}

export default Login;
